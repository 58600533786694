import { FC } from "react";
import { useParams } from "react-router-dom";

import { FollowUps } from "./FollowUps";
import { AdHocFollowUp } from "./AdHocFollowUp";
import { ContactHistory } from "./ContactHistory";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { ReferralCenterDetails } from "./ReferralCenterDetails";

import "./style.scss";

const ReferralCenter: FC = () => {
  useDocumentTitle("Referral Center Details");
  const { id } = useParams();

  return (
    <div className="referral-center-details-page">
      <ReferralCenterDetails id={id} />
      <FollowUps referralCenterID={Number(id)} />
      <AdHocFollowUp referralCenterID={Number(id)} />
      <ContactHistory referralCenterID={Number(id)} />
    </div>
  );
};

export default ReferralCenter;
