export const API_PREFIX = process.env.REACT_APP_API_BASE_URL;

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

export const PHONE_MASK = "(999) 999-9999";

// Urls
export const URL = {
  LOGIN: "/login",
  STYLE_GUIDE: "/style-guide",

  // private URLs
  DASHBOARD: "/",
  FOLLOW_UPS: "/follow-ups",
  REFERRAL_CENTER: "/referral-center",
};

// https://github.com/react-component/field-form/blob/master/src/utils/messages.ts
export const VALIDATE_MESSAGES = {
  required: "Required!",
  types: {
    email: "Invalid Email",
    number: "Invalid number!",
  },
  string: {
    max: "Max characters ${max}",
  },
  number: {
    range: "Must be between ${min} and ${max}",
  },
  array: {
    max: "Must be less than ${max} characters!",
  },
};
