import { FC, useContext, useEffect, useState } from "react";

import { Col, Form, Input, message, Row } from "antd";

import Button from "../../components/Button";
import Select from "../../components/Select";
import Container from "../../components/Layout/Container";

import { ACTIONS } from "../../hooks/useAppStore";
import { CONTACT_TYPE } from "../../utils/enums";
import { AppContext } from "../../contexts/appContext";
import { VALIDATE_MESSAGES } from "../../utils/constants";
import { IFollowUpsForm } from "../../interfaces/followUps";
import { History } from "../../interfaces/contactHistory";
import FollowUpsService from "../../services/followUps.service";

import "./adhoc-followup.scss";

interface IAdHocFollowUpProps {
  referralCenterID: number;
}

export const AdHocFollowUp: FC<IAdHocFollowUpProps> = ({ referralCenterID }: IAdHocFollowUpProps) => {
  const [form] = Form.useForm();
  const [{ contactHistory, onBehalfOfUserId }, dispatch] = useContext(AppContext);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      referralCenterID: referralCenterID,
    });
  }, []);

  const handelSubmit = async (values: IFollowUpsForm) => {
    setIsSubmitting(true);
    values.referralCenterID = referralCenterID;

    const response = await FollowUpsService.addAdhocFollowUp(values);
    if (response.status === 200) {
      if (response.data?.noteID && contactHistory) {
        const thisContactHistory: History[] = [...contactHistory];
        thisContactHistory.unshift(response.data);
        dispatch({
          type: ACTIONS.SET_RC_CONTACT_HISTORY,
          contactHistory: thisContactHistory,
        });
        message.success("Adhoc Follow-Up Added Successfully");
        form.resetFields();
      } else {
        message.error("Error in Adding Adhoc Follow-Up");
      }
    } else {
      message.error("Error in Adding Adhoc Follow-Up");
    }

    setIsSubmitting(false);
  };

  return (
    <div className="ad-hoc-follow-up-section">
      <Container>
        <Form
          form={form}
          className="rc-form"
          layout="vertical"
          autoComplete="off"
          onFinish={handelSubmit}
          validateMessages={VALIDATE_MESSAGES}
        >
          <Row gutter={12}>
            <Col span={14}>
              <Form.Item
                className="ad-hoc-follow-up-section__description-input"
                label="Description:"
                name="text"
                rules={[{ required: true }]}
              >
                <Input.TextArea placeholder="Describe your client contact..." />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Title:" name="title" rules={[{ required: true, max: 50 }]}>
                <Input placeholder="Title" />
              </Form.Item>
              <Select
                label="Contact Type:"
                name="contactMethodID"
                placeholder="Contact Type"
                options={CONTACT_TYPE}
                isRequired
              />
              <Button variant="primary" htmlType="submit" loading={isSubmitting} block>
                {isSubmitting ? "Saving..." : "Save Comment"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};
