import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import useLogout from "../hooks/useLogout";
import AppLayout from "../components/Layout";

import { isCurrentUserExecutive } from "../utils/helpers";

export const AuthGuard = () => {
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate(useLogout());
    } else if (isCurrentUserExecutive() && !auth.tsmId && location.pathname !== "/") {
      navigate("/");
    }
  }, [location]);

  if (!auth) {
    return <div></div>;
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};
