import { FC, useEffect, useState } from "react";

import { Form, Input } from "antd";
import { CloseOutlined, PushpinFilled } from "@ant-design/icons";

import Modal from "../../components/Modal";
import Button from "../../components/Button";

import { VALIDATE_MESSAGES } from "../../utils/constants";
import { IReferralCenterNotes } from "../../interfaces/referralCenters";

import "./note-form-modal.scss";

interface IProps {
  error?: string;
  success?: string;
  showModal: boolean;
  hideModal: () => void;
  isSubmitting: boolean;
  referralCenterID: string;
  selectedNote: IReferralCenterNotes | null;
  handleSubmit: (formData: IReferralCenterNotes) => void;
}

const NoteFormModal: FC<IProps> = (props: IProps) => {
  const [form] = Form.useForm();

  const [modalTitle, setModalTitle] = useState("Add Note");

  useEffect(() => {
    if (props.selectedNote) {
      setModalTitle("Edit Note");
      form.setFieldsValue(props.selectedNote);
    } else {
      setModalTitle("Add Note");
      form.setFieldsValue({
        text: "",
      });
    }
  }, [props.selectedNote]);

  useEffect(() => {
    if (!props.selectedNote && props.success) {
      form.resetFields();
    }
  }, [props.success]);

  const handleClose = () => {
    props.hideModal();
    form.resetFields();
  };

  return (
    <Modal
      headerIcon={<PushpinFilled />}
      title={modalTitle}
      error={props.error}
      success={props.success}
      className="rc-note-form-modal"
      visible={props.showModal}
      onCancel={handleClose}
    >
      <Form
        form={form}
        className="rc-note-form"
        layout="vertical"
        autoComplete="off"
        onFinish={props.handleSubmit}
        validateMessages={VALIDATE_MESSAGES}
      >
        <div className="rc-note-form__inputs">
          <Form.Item label="Details" name="text" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
        </div>
        <div className="rc-note-form__actions">
          <Button variant="link" htmlType="button" icon={<CloseOutlined />} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" htmlType="submit" loading={props.isSubmitting}>
            {props.isSubmitting ? "Saving..." : "Save"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default NoteFormModal;
