import { FC, useContext, useEffect, useState } from "react";

import { Col, Divider, Form, Input, message, Row, Spin } from "antd";

import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Select from "../../components/Select";

import { CONTACT_TYPE } from "../../utils/enums";
import { ACTIONS } from "../../reducers/appReducer";
import { AppContext } from "../../contexts/appContext";
import { VALIDATE_MESSAGES } from "../../utils/constants";
import FollowUpsService from "../../services/followUps.service";
import { FollowUpDetail, IFollowUpsForm } from "../../interfaces/followUps";

import "./style.scss";

type FollowUpCompleteFormProps = {
  followUpId: number | undefined;
  closeModal: () => void;
};

export const CompleteFollowUp: FC<FollowUpCompleteFormProps> = ({ followUpId, closeModal }) => {
  const [form] = Form.useForm();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [{ onBehalfOfUserId }, dispatch] = useContext(AppContext);
  const [data, setData] = useState<FollowUpDetail | null>(null);

  useEffect(() => {
    if (followUpId) {
      form.resetFields();
      setSuccess("");
      setError("");
      setShow(true);

      (async () => {
        await fetchData(followUpId);
      })();
    } else {
      setShow(false);
    }
  }, [followUpId]);

  const fetchData = async (followUpID: number) => {
    setIsLoading(true);
    const response = await FollowUpsService.getFollowUpDetail(followUpID, onBehalfOfUserId);
    if (response && response.status === 200) {
      setData(response.data);
    } else {
      message.error("Error in Fetching Follow-up");
    }
    setIsLoading(false);
  };

  const handelSubmit = async (values: IFollowUpsForm) => {
    if (followUpId) {
      setIsSaving(true);
      let isSuccess = false;

      try {
        const formData = {
          followUpID: Number(followUpId),
          title: values.title,
          text: values.text,
          contactMethodID: Number(values.contactMethodID),
        };
        const history = await FollowUpsService.completeFollowUp(formData, onBehalfOfUserId);
        if (history) {
          dispatch({
            type: ACTIONS.COMPLETE_FOLLOWUP,
            followUpID: followUpId,
          });
        }
        form.resetFields();
        setSuccess("Follow-up closed.");
        isSuccess = true;
      } catch (err: unknown) {
        setError("There was an error while closing the follow-up.");
      }

      setIsSaving(false);

      if (isSuccess) {
        setTimeout(closeModal, 2000);
      }
    }
  };

  return (
    <Modal
      className="complete-followup-modal"
      title={data?.noteTitle}
      subTitle="Complete Follow-up"
      visible={show}
      onCancel={closeModal}
      error={error}
      success={success}
    >
      {isLoading ? (
        <div className="contact-history-section__loading">
          <Spin size="large" />
          <span>Loading Follow Up Details...</span>
        </div>
      ) : (
        <>
          <p>{data?.noteText}</p>
          <Divider />
          <Row gutter={20} className="client-detail">
            <Col span={12}>
              <div className="detail-left">
                <span className="followup-title">{data?.rcName}</span>
                <span className="followup-client">{data?.rcPocName}</span>
                <span className="followup-client-street">{`${data?.rcPocAddrLine1}, ${data?.rcPocAddrLine2}`}</span>
                <span className="followup-client-address">{`${data?.rcPocAddrCity}, ${data?.rcPocAddrState} ${data?.rcPocAddrZip}`}</span>
                <span className="followup-id">ID: {data?.rcPartnerCode}</span>
              </div>
            </Col>
            <Col span={12} className="detail-left">
              <label>
                Mobile:
                <a href={`tel:${data?.rcPocMobilePhone}`}>{data?.rcPocMobilePhone}</a>
              </label>
              <Divider />
              <label>
                Office:
                <a href={`tel:${data?.rcPocOfficePhone}`}>{data?.rcPocOfficePhone}</a>
              </label>
              <Divider orientationMargin="none" />
              <label>
                e-Mail:
                <a href={`mailto:${data?.rcPocEmail}`}>{data?.rcPocEmail}</a>
              </label>
            </Col>
          </Row>
          <Divider />
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={handelSubmit}
            validateMessages={VALIDATE_MESSAGES}
          >
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item label="Description:" name="text" rules={[{ required: true }]}>
                  <Input.TextArea placeholder="Describe your client contact..." />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Title:" name="title" rules={[{ required: true, max: 50 }]}>
                  <Input placeholder="Title" />
                </Form.Item>
                <Select
                  label="Contact Type:"
                  name="contactMethodID"
                  placeholder="Contact Type"
                  options={CONTACT_TYPE}
                  isRequired
                />
                <Col>
                  <Button variant="primary" htmlType="submit" block loading={isSaving}>
                    {isSaving ? "Saving..." : "Save & Close Follow-up"}
                  </Button>
                </Col>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Modal>
  );
};
