import { useReducer } from "react";

import { ACTIONS, Dispatch, initialState, appReducer, State } from "../reducers/appReducer";
export { ACTIONS, appReducer };

export const useAppStore = (): [State, Dispatch] => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return [state, dispatch];
};
