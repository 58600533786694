import { LoggedInUser } from "../hooks/useAuth";

export const getCurrentUser = (): LoggedInUser | undefined => {
  return JSON.parse(window.localStorage.getItem("user") || "null") || undefined;
};

export const getAccessToken = (): string | undefined => {
  const user = getCurrentUser();

  return user?.token;
};

export const setAccessToken = (token: string) => {
  const user = getCurrentUser();
  if (!user) {
    throw new Error("No current user found!");
  }
  setCurrentUser({ ...user, token });
};

export const setCurrentUser = (user: LoggedInUser) => {
  window.localStorage.setItem("user", JSON.stringify(user));
};

export const setTsmIdToUser = (tsmId: number) => {
  const user = getCurrentUser();
  if (user) {
    if (tsmId) {
      setCurrentUser({ ...user, tsmId });
    } else {
      delete user["tsmId"];
      setCurrentUser({ ...user });
    }
  }
};

export const removeCurrentUserSession = () => {
  window.localStorage.removeItem("user");
};
