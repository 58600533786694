import React, { FC } from "react";
import { Result } from "antd";

import Container from "../../components/Layout/Container";

import "./styles.scss";

const NoRole: FC = () => {
  return (
    <Container>
      <div className="no-role-page">
        <Result title="Thank you for visiting." subTitle="We do not have any actions you can take!" />
      </div>
    </Container>
  );
};

export default NoRole;
