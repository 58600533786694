import { FC } from "react";

import { Form, Select as AntSelect } from "antd";

import { IEnum } from "../../interfaces/common";

interface ISelectProps {
  label: string;
  name: string;
  placeholder: string;
  options: IEnum[];
  isRequired?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  withForm?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  onChange?: (value: string) => void;
}

const Select: FC<ISelectProps> = ({
  name,
  label,
  options,
  onChange,
  withForm = true,
  placeholder,
  isRequired = false,
  ...props
}: ISelectProps) => {
  const renderSelect = () => {
    return (
      <AntSelect
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filterOption={(input, option: any) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) =>
          optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())
        }
        onChange={(value: string) => {
          return onChange ? onChange(value) : null;
        }}
        {...props}
      >
        {options.map((o: IEnum) => (
          <AntSelect.Option key={o.value} value={o.value}>
            {o.label}
          </AntSelect.Option>
        ))}
      </AntSelect>
    );
  };

  if (withForm) {
    return (
      <Form.Item label={label} name={name} rules={[{ required: isRequired }]}>
        {renderSelect()}
      </Form.Item>
    );
  }

  return renderSelect();
};

export default Select;
