import { IReferralCenterForm } from "../interfaces/referralCenters";

export const defaultReferralCenterFormData: IReferralCenterForm = {
  name: "",
  partnerCode: "",
  pocFirstName: "",
  pocLastName: "",
  pocAddress1: "",
  pocAddress2: "",
  pocState: "",
  pocEmail: "",
  pocCity: "",
  pocOfficePhone: "",
  pocOfficePhoneExt: "",
  pocZip: "",
  pocMobilePhone: "",
};
