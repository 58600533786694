import { FC, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import cn from "classnames";
import { Layout } from "antd";

import Header from "./Header";
import Footer from "./Footer";

import { IEnum } from "../../interfaces/common";
import { IUserTsms } from "../../interfaces/users";
import UserService from "../../services/user.service";
import { isCurrentUserExecutive } from "../../utils/helpers";
import { AppContextProvider } from "../../contexts/appContext";

import "./style.scss";

interface IAppLayoutProps {
  children: ReactNode;
  className?: string;
}

const AppLayout: FC<IAppLayoutProps> = (props: IAppLayoutProps) => {
  const location = useLocation();
  const isExecutive = isCurrentUserExecutive();
  const [tsms, setTsms] = useState<IEnum[]>([]);

  useEffect(() => {
    if (isExecutive) {
      (async () => {
        await fetchTsms();
      })();
    }
  }, [isExecutive]);

  const fetchTsms = async () => {
    const response = await UserService.getTsms();
    if (response?.status === 200) {
      const tsmsData = response.data.map(
        (tsm: IUserTsms) =>
          ({
            value: tsm.userID,
            label: tsm.name,
          } as IEnum)
      );
      setTsms(tsmsData);
    }
  };

  const layoutClassName = cn(
    "app-layout",
    {
      "app-layout-executive": isExecutive && location.pathname === "/",
    },
    props.className
  );

  return (
    <AppContextProvider>
      <Layout className={layoutClassName}>
        <Header isExecutive={isExecutive} tsms={tsms} />
        <Layout.Content>
          <div className="app-page-wrapper">{props.children}</div>
        </Layout.Content>
        <Footer />
      </Layout>
    </AppContextProvider>
  );
};

export default AppLayout;
