import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Form, Input, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { debounce } from "lodash";

import Table from "../../components/Table";
import Button from "../../components/Button";
import Select from "../../components/Select";
import EditReferralCenterFormModal from "../../components/EditReferralCenterFormModal";

import { URL } from "../../utils/constants";
import { RC_STATUS } from "../../utils/enums";
import { AppContext } from "../../contexts/appContext";
import { getRcStatus, formatCurrency } from "../../utils/helpers";
import { defaultReferralCenterFormData } from "../../utils/defaultData";
import ReferralCentersService from "../../services/referralCenters.service";
import { IReferralCenters, IReferralCenterForm } from "../../interfaces/referralCenters";

export const RcList: FC = () => {
  const [{ onBehalfOfUserId }, dispatch] = useContext(AppContext);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [data, setData] = useState<IReferralCenters[]>([]);
  const [filteredData, setFilteredData] = useState<IReferralCenters[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorHasExclamation, setErrorHasExclamation] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<number>(0);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [onBehalfOfUserId]);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await ReferralCentersService.get(undefined, onBehalfOfUserId);
    if (response && response.status === 200) {
      setData(response.data);
      setFilteredData(response.data);
    } else {
      message.error("Error in Fetching Uncompleted Follow-ups");
    }
    setIsLoading(false);
  };

  const openAddModal = () => {
    setError("");
    setSuccess("");
    setShowModal(true);
  };

  const renderRcTable = () => {
    // eslint-disable-next-line no-console
    console.log(">>> thisFilteredData", filteredData.length);

    const columns = [
      {
        title: "Name",
        key: "name",
        render: (thisData: IReferralCenters) => (
          <Link className="table-link" to={`${URL.REFERRAL_CENTER}/${thisData.referralCenterID}`}>
            {thisData.name}
          </Link>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        className: "w-20",
        render: (status: number) => <span className="capitalize">{getRcStatus(status, true)}</span>,
      },
      {
        title: "Total Revenue",
        dataIndex: "totalRevenue",
        key: "totalRevenue",
        className: "w-30",
        render: (totalRevenue: number) => <span>{formatCurrency(totalRevenue)}</span>,
      },
      {
        title: "Referrals",
        dataIndex: "qtyReferrals",
        key: "qtyReferrals",
        className: "w-20",
      },
      {
        title: "Closes",
        dataIndex: "qtyCloses",
        key: "qtyCloses",
        className: "w-18",
      },
    ];

    let list = [...filteredData];
    if (selectedFilter) {
      list = filteredData.filter((rc: IReferralCenters) => rc.status === selectedFilter);
    }
    const sortedList = [...list].sort(
      (a, b) =>
        b.totalRevenue - a.totalRevenue ||
        b.qtyCloses - a.qtyCloses ||
        b.qtyReferrals - a.qtyReferrals ||
        a.name.localeCompare(b.name)
    );

    return (
      <Table
        isLoading={isLoading}
        dataSource={sortedList}
        columns={columns}
        rowKey="referralCenterID"
        className="rc-list-table"
      />
    );
  };

  const handleSuccess = (id: number, formData: IReferralCenterForm) => {
    const newData: IReferralCenters = {
      referralCenterID: id,
      name: formData.name,
      partnerCode: formData.partnerCode || "",
      pocFirstName: formData.pocFirstName,
      pocLastName: formData.pocLastName,
      status: 1,
      qtyCloses: 0,
      qtyReferrals: 0,
      totalRevenue: 0,
    };
    data.push(newData);
  };

  const handleSubmit = async (values: IReferralCenterForm) => {
    let isSuccess = false;
    setError("");
    setSuccess("");
    setIsSubmitting(true);

    const response = await ReferralCentersService.add(values, onBehalfOfUserId);
    if (response.status === 200) {
      handleSuccess(response.data.referralCenterID, values);
      setSuccess("Referral Center Created.");
      isSuccess = true;
    } else if (response.status === 400) {
      setError(response.data);
      setErrorHasExclamation(false);
    } else {
      setError("There was an error while creating the referral center.");
    }

    setIsSubmitting(false);

    if (isSuccess) {
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    }
  };

  const renderModal = () => {
    return (
      <EditReferralCenterFormModal
        error={error}
        errorHasExclamation={errorHasExclamation}
        success={success}
        showModal={showModal}
        isSubmitting={isSubmitting}
        initialFormData={defaultReferralCenterFormData}
        handleSubmit={handleSubmit}
        hideModal={() => setShowModal(false)}
      />
    );
  };

  const doRCFilter = (query: string) => {
    if (!query) {
      return setFilteredData(data);
    }

    const debouncedFilter = debounce(() => {
      const thisFilteredData = [...data].filter((rc: IReferralCenters) => {
        return (
          rc.name.toLowerCase().includes(query.toLowerCase()) ||
          rc.pocFirstName.toLowerCase().includes(query.toLowerCase()) ||
          rc.pocLastName.toLowerCase().includes(query.toLowerCase()) ||
          rc.partnerCode === query
        );
      });
      setFilteredData(thisFilteredData);
    }, 500);

    debouncedFilter();
  };

  return (
    <div className="dashboard-wrapper__rc-list">
      <h1 className="main-title">RC List</h1>
      <div className="dashboard-wrapper__rc-list__actions">
        <Button variant="secondary" icon={<PlusOutlined />} onClick={() => openAddModal()}>
          Add New RC
        </Button>

        <div className="dashboard-wrapper__rc-list__actions__filter">
          <Form.Item label="Search:" name="filterByText" className="ant-form-search">
            <Input
              placeholder="Search"
              onChange={event => doRCFilter(event.target.value)}
              autoComplete="off"
              allowClear
            />
          </Form.Item>
          <Select
            label="Filter By:"
            name="filterByStatus"
            placeholder="Select"
            options={RC_STATUS}
            allowClear
            onChange={(value: string) => setSelectedFilter(Number(value) || 0)}
          />
        </div>
      </div>

      {renderRcTable()}
      {renderModal()}
    </div>
  );
};
