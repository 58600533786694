import { FC, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import cn from "classnames";
import { Divider, Layout } from "antd";

import Select from "../Select";
import Container from "./Container";

import { IEnum } from "../../interfaces/common";
import { AppContext } from "../../contexts/appContext";
import { ACTIONS } from "../../hooks/useAppStore";
import { isCurrentUserExecutive } from "../../utils/helpers";
import { getCurrentUser, setTsmIdToUser, removeCurrentUserSession } from "../../services/localStorage";

import "./header.scss";

interface IHeaderProps {
  isExecutive: boolean;
  tsms: IEnum[];
}

const Header: FC<IHeaderProps> = ({ isExecutive, tsms }: IHeaderProps) => {
  const location = useLocation();
  const [{ onBehalfOfUserId }, dispatch] = useContext(AppContext);

  const currentUser = getCurrentUser();
  if (!currentUser) {
    return <div></div>;
  }

  useEffect(() => {
    if (isCurrentUserExecutive() && !onBehalfOfUserId && currentUser.tsmId) {
      dispatch({
        type: ACTIONS.SET_TSM_USER,
        onBehalfOfUserId: Number(currentUser.tsmId),
      });
    }
  }, []);

  const handleChange = (value: string) => {
    setTsmIdToUser(Number(value));

    dispatch({
      type: ACTIONS.SET_TSM_USER,
      onBehalfOfUserId: value ? Number(value) : 0,
    });
  };

  const headerClassName = cn("app-header", {
    "app-header-executive": isExecutive,
  });

  return (
    <Layout.Header className={headerClassName}>
      <Container>
        <div className="header-content">
          <div className="app-header__left">
            <Link to="/" className="app-header__logo_wrapper">
              <div className="app-header__logo"></div>
              <h1>TSM Portal</h1>
              {isExecutive && <h3>EXECUTIVE</h3>}
            </Link>
          </div>
          <div className="app-header__right">
            <span className="app-header__username">{currentUser.fullName}</span>
            <Divider orientation="center" type="vertical" orientationMargin={30} />
            <Link to="/login" className="app-header__logout" onClick={removeCurrentUserSession}>
              Logout
            </Link>
          </div>
        </div>
      </Container>
      {isExecutive && tsms.length && location.pathname === "/" ? (
        <div className="app-header__select-tsm-user">
          <Container>
            <div className="app-header__select-tsm-user__wrapper">
              <label>Currently Viewing Records for:</label>
              <Select
                label="Currently Viewing Records for:"
                name="tsmUserId"
                placeholder="Select"
                options={tsms}
                onChange={handleChange}
                value={onBehalfOfUserId || null}
                withForm={false}
              />
            </div>
          </Container>
        </div>
      ) : (
        <div></div>
      )}
    </Layout.Header>
  );
};

export default Header;
