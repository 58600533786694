import { FC, ReactNode, useEffect } from "react";
import { useLocation } from "react-router";

interface IAutoScrollToTopProps {
  children: ReactNode;
}

const AutoScrollToTop: FC<IAutoScrollToTopProps> = (props: IAutoScrollToTopProps) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

export default AutoScrollToTop;
