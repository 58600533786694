import API from "./axios";
import { ILoginValues } from "../interfaces/auth";
import { removeCurrentUserSession, setCurrentUser, getCurrentUser as getCurrentLocalUser } from "./localStorage";

class AuthService {
  login(values: ILoginValues) {
    return API.post("/Users/authenticate", {
      userName: values.username,
      password: values.password,
    })
      .then(response => {
        if (response.data.token) {
          setCurrentUser(response.data);
        }
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  logout() {
    removeCurrentUserSession();
  }

  getCurrentUser() {
    return getCurrentLocalUser();
  }
}

export default new AuthService();
