import { FC } from "react";
import useAuth from "../../hooks/useAuth";

export type RbacProps = {
  elementForValidRoles: JSX.Element;
  elementForInvalidRoles: JSX.Element;
  validRoles: string[];
};

const Rbac: FC<RbacProps> = ({ elementForInvalidRoles, elementForValidRoles, validRoles }) => {
  const user = useAuth();
  const currentUserRoles = user?.roles || [];
  const validRoleExistsInUser = currentUserRoles.filter(value => validRoles.includes(value)).length;

  return validRoleExistsInUser ? elementForValidRoles : elementForInvalidRoles;
};

export default Rbac;
