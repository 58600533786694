import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { message } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";

import Container from "../../components/Layout/Container";
import FollowUpsCards from "../../components/FollowUpsCards";

import { URL } from "../../utils/constants";
import { sortBy } from "../../utils/helpers";
import { IFollowUps } from "../../interfaces/followUps";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import FollowUpsService from "../../services/followUps.service";
import { AppContext } from "../../contexts/appContext";

import "./style.scss";

const FollowUps: FC = () => {
  useDocumentTitle("Uncompleted Follow-Ups");

  const [data, setData] = useState<IFollowUps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [{ lastCompletedFollowUpID, onBehalfOfUserId }] = useContext(AppContext);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [lastCompletedFollowUpID]);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await FollowUpsService.getUncompletedFollowUps(onBehalfOfUserId);
    if (response && response.status === 200) {
      const followUpsSortedByOldest = sortBy("dueDate", "ASC", response.data.results, dueDate =>
        new Date(dueDate as string).getTime()
      );
      setData(followUpsSortedByOldest as unknown as IFollowUps[]);
    } else {
      message.error("Error in Fetching Uncompleted Follow-Ups");
    }
    setIsLoading(false);
  };

  return (
    <div className="follow-ups-page">
      <Container>
        <Link className="app-link back-link" to={URL.DASHBOARD}>
          <UnorderedListOutlined />
          Back to List
        </Link>
        <div className="follow-ups-page__content">
          <h1 className="follow-ups-page__content__title main-title">Uncompleted Follow-Ups</h1>

          <div className="follow-ups-page__content__cards">
            <FollowUpsCards gutter={[20, 20]} data={data} isLoading={isLoading} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FollowUps;
