import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Col, message, Row, Skeleton } from "antd";
import { CloseOutlined, UnorderedListOutlined } from "@ant-design/icons";

import NoteFormModal from "./NoteFormModal";
import Button from "../../components/Button";
import SquareCard from "../../components/SquareCard";
import Container from "../../components/Layout/Container";
import EditReferralCenterFormModal from "../../components/EditReferralCenterFormModal";

import { URL } from "../../utils/constants";
import { AppContext } from "../../contexts/appContext";
import { formatPhoneNumber } from "../../utils/helpers";
import { defaultReferralCenterFormData } from "../../utils/defaultData";
import ReferralCentersService from "../../services/referralCenters.service";
import { IReferralCenterDetails, IReferralCenterForm, IReferralCenterNotes } from "../../interfaces/referralCenters";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import "./referral-center-details.scss";

interface IProps {
  id: string | undefined;
}

export const ReferralCenterDetails: FC<IProps> = ({ id }: IProps) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [{ onBehalfOfUserId }] = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showNoteModal, setShowNoteModal] = useState<IReferralCenterNotes | boolean>(false);
  const [deleteNoteId, setDeleteNoteId] = useState<number>(0);
  const [referralCenterKpi, setReferralCenterKpi] = useState<IReferralCenterDetails | null>(null);
  const [referralCenterNotes, setReferralCenterNotes] = useState<IReferralCenterNotes[] | null>(null);
  const [referralCenterData, setReferralCenterData] = useState<IReferralCenterForm>(defaultReferralCenterFormData);

  useEffect(() => {
    (async () => {
      await fetchReferralCenterKpi();
      await fetchReferralCenterNotes();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (showEditModal) {
        setError("");
        setSuccess("");
        await fetchReferralCenterData();
      }
    })();
  }, [showEditModal]);

  if (!id) {
    return <div></div>;
  }

  const fetchReferralCenterKpi = async () => {
    setReferralCenterKpi(null);
    const response = await ReferralCentersService.getDetailsWithKpi(Number(id), onBehalfOfUserId);
    if (response.status === 200) {
      setReferralCenterKpi(response.data);
    } else {
      message.error("Error in Fetching Referral Center Details");
    }
  };

  const fetchReferralCenterNotes = async () => {
    setReferralCenterNotes(null);
    const response = await ReferralCentersService.getNotes(Number(id), onBehalfOfUserId);
    if (response.status === 200) {
      setReferralCenterNotes(response.data);
    } else {
      setReferralCenterNotes([]);
      message.error("Error in Fetching Referral Center Notes");
    }
  };

  const fetchReferralCenterData = async () => {
    setError("");
    setReferralCenterData(defaultReferralCenterFormData);
    setIsLoadingForm(true);
    const response = await ReferralCentersService.get(Number(id), onBehalfOfUserId);
    if (response.status === 200) {
      setReferralCenterData(response.data);
    } else {
      setError("Error in Fetching Referral Center.");
    }
    setIsLoadingForm(false);
  };

  const handleSubmit = async (values: IReferralCenterForm) => {
    let isSuccess = false;
    setIsSubmitting(true);

    const response = await ReferralCentersService.update(Number(id), values, onBehalfOfUserId);
    if (response.status === 200) {
      if (referralCenterKpi) {
        const thisData = { ...referralCenterKpi, ...values };
        setReferralCenterKpi(thisData);
      }
      setSuccess("Referral Center Updated.");
      isSuccess = true;
    } else {
      setError("There was an error while updating the referral center.");
    }

    setIsSubmitting(false);

    if (isSuccess) {
      setTimeout(() => {
        setShowEditModal(false);
      }, 2000);
    }
  };

  const renderDetails = () => {
    if (!referralCenterKpi) {
      return (
        <div className="referral-center-detail-section__details">
          <div className="referral-center-detail-section__details__top">
            <Skeleton />
          </div>
          <Skeleton />
        </div>
      );
    }

    return (
      <div className="referral-center-detail-section__details">
        <div className="referral-center-detail-section__details__top">
          <div className="referral-center-detail-section__details__name">{referralCenterKpi.name}</div>
          <div className="referral-center-detail-section__details__poc-name">
            {referralCenterKpi.pocFirstName} {referralCenterKpi.pocLastName}
          </div>
          {referralCenterKpi.pocAddress1 ||
          referralCenterKpi.pocAddress2 ||
          referralCenterKpi.pocCity ||
          referralCenterKpi.pocState ||
          referralCenterKpi.pocZip ? (
            <div className="referral-center-detail-section__details__address">
              {referralCenterKpi.pocAddress1}
              {referralCenterKpi.pocAddress1 && <br />}
              {referralCenterKpi.pocAddress2 && (
                <>
                  {referralCenterKpi.pocAddress2}
                  <br />
                </>
              )}
              {referralCenterKpi.pocCity ? `${referralCenterKpi.pocCity}, ` : ""}
              {referralCenterKpi.pocState} {referralCenterKpi.pocZip}
            </div>
          ) : (
            <></>
          )}
          <div className="referral-center-detail-section__details__id">ID: {referralCenterKpi.partnerCode}</div>
        </div>
        {referralCenterKpi.pocMobilePhone && (
          <div className="referral-center-detail-section__details__mobile">
            <span>Mobile: </span>
            <a className="app-link" href={`tel:${referralCenterKpi.pocMobilePhone}`}>
              {formatPhoneNumber(referralCenterKpi.pocMobilePhone)}
            </a>
          </div>
        )}
        {referralCenterKpi.pocOfficePhone && (
          <div className="referral-center-detail-section__details__office">
            <span>Office: </span>
            <a
              className="app-link"
              href={`tel:${referralCenterKpi.pocOfficePhone}${
                referralCenterKpi.pocOfficePhoneExt ? "p" + referralCenterKpi.pocOfficePhoneExt : ""
              }`}
            >
              {formatPhoneNumber(referralCenterKpi.pocOfficePhone)}
              {referralCenterKpi.pocOfficePhoneExt && <> x{referralCenterKpi.pocOfficePhoneExt}</>}
            </a>
          </div>
        )}
        <div className="referral-center-detail-section__details__email">
          <span>e-Mail: </span>
          <a className="app-link" href={`mailto:${referralCenterKpi.pocEmail}`}>
            {referralCenterKpi.pocEmail}
          </a>
        </div>
      </div>
    );
  };

  const renderKpi = () => {
    return (
      <div className="referral-center-detail-section__kpi">
        <Row gutter={20}>
          <Col span={8}>
            <div className="referral-center-detail-section__kpi__item">
              <strong>
                {referralCenterKpi?.closePercentage || 0}
                <small>%</small>
              </strong>
              <span>Close Ratio</span>
            </div>
          </Col>
          <Col span={8}>
            <div className="referral-center-detail-section__kpi__item">
              <strong>{referralCenterKpi?.totalReferral || 0}</strong>
              <span>{referralCenterKpi?.totalReferral == 1 ? "Referral" : "Referrals"}</span>
            </div>
          </Col>
          <Col span={8}>
            <div className="referral-center-detail-section__kpi__item">
              <strong>{referralCenterKpi?.totalClose || 0}</strong>
              <span>{referralCenterKpi?.totalClose == 1 ? "Close" : "Closes"}</span>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const handleSubmitNote = async (values: IReferralCenterNotes) => {
    setIsSubmitting(true);
    const isEditAction = typeof showNoteModal === "object";
    let response;
    if (isEditAction) {
      response = await ReferralCentersService.updateNote(
        Number(showNoteModal.noteID),
        Number(id),
        values,
        onBehalfOfUserId
      );
    } else {
      response = await ReferralCentersService.addNote(Number(id), values, onBehalfOfUserId);
    }
    if (response.status === 200) {
      if (response.data) {
        const thisNotes = referralCenterNotes ? [...referralCenterNotes] : [];
        if (isEditAction) {
          const index = thisNotes.findIndex(n => n.noteID === showNoteModal.noteID);
          thisNotes[index].text = values.text;
        } else {
          thisNotes.unshift(response.data);
        }
        setReferralCenterNotes(thisNotes);
      }
      setSuccess(isEditAction ? "Note Updated" : "Note Added");
      setTimeout(() => {
        setShowNoteModal(false);
        setError("");
        setSuccess("");
      }, 2000);
    } else {
      setError("There was an error while adding note.");
    }
    setIsSubmitting(false);
  };

  const renderLoadingNotes = () => {
    const title = !referralCenterNotes ? "Fetching..." : "Not Found";

    return (
      <>
        <div className="referral-center-detail-section__notes__item">
          <SquareCard title={title}>
            <div className="referral-center-detail-section__notes__item__content">
              {!referralCenterNotes ? <Skeleton /> : "Note Not Found"}
            </div>
          </SquareCard>
        </div>
        {referralCenterNotes?.length === 0 && (
          <div className="referral-center-detail-section__notes__add">
            <Button variant="link" onClick={() => setShowNoteModal(true)}>
              Add Note
            </Button>
          </div>
        )}
      </>
    );
  };

  const handleDeleteNote = async (noteID: number) => {
    setIsDeleting(true);
    const response = await ReferralCentersService.deleteNote(noteID, Number(id), onBehalfOfUserId);
    if (response.status === 200) {
      const thisNotes = referralCenterNotes ? [...referralCenterNotes] : [];
      const updatedNotes = thisNotes.filter(n => n.noteID !== noteID);
      setReferralCenterNotes(updatedNotes);
    } else {
      message.error("There was an error while deleting note.");
    }
    setIsDeleting(false);
  };

  const deleteNotesOverlay = (noteId: number) => {
    return (
      <div className="referral-center-detail-section__notes__item__delete">
        <em>Are you sure you want to delete this note?</em>
        <div className="referral-center-detail-section__notes__item__delete__actions">
          <Button variant="primary" htmlType="button" onClick={() => handleDeleteNote(noteId)} disabled={isDeleting}>
            {isDeleting ? "Deleting..." : "Delete"}
          </Button>
          <Button
            variant="link"
            htmlType="button"
            icon={<CloseOutlined />}
            onClick={() => setDeleteNoteId(0)}
            disabled={isDeleting}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  const renderNotes = () => {
    if (!referralCenterNotes || referralCenterNotes.length === 0) {
      return renderLoadingNotes();
    }

    return (
      <>
        <SimpleBar className="referral-center-detail-section__notes__scrollbar">
          {referralCenterNotes.map(note => (
            <div className="referral-center-detail-section__notes__item" key={note.noteID}>
              <SquareCard
                title="Note"
                actionEdit={() => setShowNoteModal(note)}
                actionDelete={() => setDeleteNoteId(note.noteID)}
              >
                <div className="referral-center-detail-section__notes__item__content">{note.text}</div>
                {note.noteID === deleteNoteId && deleteNotesOverlay(note.noteID)}
              </SquareCard>
            </div>
          ))}
        </SimpleBar>
        <div className="referral-center-detail-section__notes__add">
          <Button variant="link" onClick={() => setShowNoteModal(true)}>
            Add Note
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <Container>
        <Link className="app-link back-link" to={URL.DASHBOARD}>
          <UnorderedListOutlined />
          Back to List
        </Link>

        <div className="referral-center-detail-section">
          {renderKpi()}
          <Row gutter={20}>
            <Col span={12}>
              <SquareCard title="Client" actionEdit={() => (referralCenterKpi ? setShowEditModal(true) : undefined)}>
                {renderDetails()}
              </SquareCard>
            </Col>
            <Col span={12}>
              <div className="referral-center-detail-section__notes">{renderNotes()}</div>
            </Col>
          </Row>
        </div>
      </Container>

      <EditReferralCenterFormModal
        referralCenterID={id}
        error={error}
        success={success}
        showModal={showEditModal}
        isSubmitting={isSubmitting}
        initialFormData={referralCenterData}
        isLoadingForm={isLoadingForm}
        handleSubmit={handleSubmit}
        hideModal={() => setShowEditModal(false)}
      />
      <NoteFormModal
        error={error}
        success={success}
        referralCenterID={id}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmitNote}
        showModal={Boolean(showNoteModal)}
        hideModal={() => {
          setError("");
          setSuccess("");
          setShowNoteModal(false);
        }}
        selectedNote={typeof showNoteModal === "object" ? showNoteModal : null}
      />
    </>
  );
};
