import { useContext, useEffect, useState } from "react";

import { message, Spin } from "antd";

import Container from "../../components/Layout/Container";
import { Collapsible, CollapsibleProps } from "../../components/Collapsible";

import { AppContext } from "../../contexts/appContext";
import { ACTIONS } from "../../hooks/useAppStore";
import { History } from "../../interfaces/contactHistory";
import ReferralCentersService from "../../services/referralCenters.service";
import { formatDateTime, getContactType, getFollowUpType, sortBy } from "../../utils/helpers";

import "./contact-history.scss";

const resolveSubtitle = (contactType: string, followUpType: string, title: string) => {
  let thisContactType = contactType;
  if (contactType === "Phone") {
    thisContactType = "Phone call";
  }

  let subtitle;
  if (followUpType === "Ad hoc") {
    subtitle = `Ad hoc ${thisContactType.toLowerCase()}`;
  } else {
    subtitle = `${contactType ? thisContactType : "Unknown"} response to "${title}"`;
  }

  return subtitle;
};

interface IContactHistoryProps {
  referralCenterID: number;
}

export const ContactHistory: React.FC<IContactHistoryProps> = ({ referralCenterID }: IContactHistoryProps) => {
  const [{ contactHistory, lastCompletedFollowUpID, onBehalfOfUserId }, dispatch] = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [lastCompletedFollowUpID]);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await ReferralCentersService.getContactHistory(referralCenterID, onBehalfOfUserId);
    if (response && response.status === 200) {
      dispatch({
        type: ACTIONS.SET_RC_CONTACT_HISTORY,
        contactHistory: response.data,
      });
    } else {
      message.error("Error in Fetching Uncompleted Follow-ups");
    }
    setIsLoading(false);
  };

  const transformToHistoryPanel = (histories: History[]): CollapsibleProps["panels"] => {
    const sortedData = sortBy("completedDateTime", "DESC", histories, completedDateTime =>
      new Date(completedDateTime as string).getTime()
    );

    return sortedData.map(history => {
      const datetime = new Date(history.completedDateTime);
      const contactType = getContactType(history.contactMethodID, true)?.toString() || "";
      const followUpType = getFollowUpType(history.followUpTypeID, true)?.toString() || "";
      const subTitle = resolveSubtitle(contactType, followUpType, history.title);

      return {
        contactMethodID: history.contactMethodID,
        followUpID: history.followUpID,
        heading: history.completedNoteTitle,
        subTitle: subTitle,
        titleRightEnd: formatDateTime(datetime.toISOString(), "MMMM dd, yyyy"),
        subtitleRightEnd: formatDateTime(datetime.toISOString(), "h:mm a"),
        contactType: contactType,
        content: history.completedNoteText,
      };
    });
  };

  return (
    <Container>
      <div className="contact-history-section">
        {isLoading ? (
          <div className="contact-history-section__loading">
            <Spin size="large" />
            <span>Loading Contact History...</span>
          </div>
        ) : (
          <Collapsible panels={transformToHistoryPanel(contactHistory || [])} />
        )}
      </div>
    </Container>
  );
};
