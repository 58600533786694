import API from "./axios";
import { IReferralCenterForm, IReferralCenterNotes } from "../interfaces/referralCenters";

class ReferralCentersService {
  get(referralCenterID?: number, tsmUserID?: number) {
    let url = "/ReferralCenters";
    if (referralCenterID) {
      url = `${url}/${referralCenterID}`;
    }
    if (tsmUserID) {
      url = `${url}?TsmUserID=${tsmUserID}`;
    }
    return API.get(`${url}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  getOptions(tsmUserID?: number) {
    return API.get(`/ReferralCenters/options${tsmUserID ? "?tsmUserID=" + tsmUserID : ""}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  getDetailsWithKpi(referralCenterID: number, tsmUserID?: number) {
    return API.get(`/ReferralCenters/${referralCenterID}/kpi${tsmUserID ? "?tsmUserID=" + tsmUserID : ""}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  getNotes(referralCenterID: number, tsmUserID?: number) {
    return API.get(`/ReferralCenters/${referralCenterID}/notes${tsmUserID ? "?tsmUserID=" + tsmUserID : ""}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  add(formData: IReferralCenterForm, tsmUserID?: number) {
    return API.post("/ReferralCenters", { ...formData, tsmUserID: tsmUserID })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  update(referralCenterID: number, formData: IReferralCenterForm, tsmUserID?: number) {
    delete formData.partnerCode;
    return API.put(`/ReferralCenters/${referralCenterID}`, { ...formData, tsmUserID: tsmUserID })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  addNote(referralCenterID: number, formData: IReferralCenterNotes, tsmUserID?: number) {
    return API.post(`/ReferralCenters/${referralCenterID}/notes`, {
      ...formData,
      referralCenterID: referralCenterID,
      tsmUserID: tsmUserID,
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  updateNote(noteID: number, referralCenterID: number, formData: IReferralCenterNotes, tsmUserID?: number) {
    return API.put(`/ReferralCenters/${referralCenterID}/notes/${noteID}`, {
      ...formData,
      noteID: noteID,
      referralCenterID: referralCenterID,
      tsmUserID: tsmUserID,
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  deleteNote(noteID: number, referralCenterID: number, tsmUserID?: number) {
    return API.delete(`/ReferralCenters/${referralCenterID}/notes/${noteID}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  async getContactHistory(referralCenterID: number, tsmUserID?: number) {
    return API.get(`/ReferralCenters/${referralCenterID}/contactHistory${tsmUserID ? "?tsmUserID=" + tsmUserID : ""}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }
}

export default new ReferralCentersService();
