import { FC, useContext, useEffect, useState } from "react";

import { message } from "antd";

import Button from "../../components/Button";
import Container from "../../components/Layout/Container";
import FollowUpsCards from "../../components/FollowUpsCards";
import { AddManualFollowUp } from "../Dashboard/AddManualFollowUp";

import { sortBy } from "../../utils/helpers";
import { AppContext } from "../../contexts/appContext";
import { IFollowUps } from "../../interfaces/followUps";
import FollowUpsService from "../../services/followUps.service";

import "./follow-ups.scss";

interface IFollowUpsProps {
  referralCenterID: number;
}

export const FollowUps: FC<IFollowUpsProps> = ({ referralCenterID }: IFollowUpsProps) => {
  const [{ lastCompletedFollowUpID, onBehalfOfUserId }, _dispatch] = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<IFollowUps[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [lastCompletedFollowUpID]);

  const fetchData = async () => {
    setIsLoading(true);
    const queryParams = {
      referralCenterID: referralCenterID,
      TsmUserID: lastCompletedFollowUpID,
    };
    if (onBehalfOfUserId) {
      queryParams.TsmUserID = onBehalfOfUserId;
    }
    const response = await FollowUpsService.get(queryParams);
    if (response && response.status === 200) {
      const sortedData = sortBy("dueDate", "ASC", response.data.results, dueDate =>
        new Date(dueDate as string).getTime()
      );
      setData(sortedData);
    } else {
      message.error("Error in Fetching Uncompleted Follow-ups");
    }
    setIsLoading(false);
  };

  return (
    <div className="referral-center-follow-ups-section">
      <Container>
        {/* <h5>Follow-Ups:</h5> */}
        <AddManualFollowUp referralCenterId={referralCenterID} />

        <div className="referral-center-follow-ups-section__cards">
          <FollowUpsCards gutter={[20, 20]} data={showAll ? data : data.slice(0, 3)} isLoading={isLoading} />
          {!isLoading && data.length > 3 && (
            <div className="text-center">
              <Button variant="link" onClick={() => setShowAll(a => !a)}>
                {showAll ? "View Less" : `View all ${data.length} Follow-Ups`}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
