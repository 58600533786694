import { lazyImport } from "../utils/lazyImport";
import { URL } from "../utils/constants";

import AuthLayout from "../components/Layout/Auth";
import Auth from "../pages/Auth";
import StyleGuide from "../pages/StyleGuide";

export const publicRoutes = [
  {
    path: URL.LOGIN,
    element: (
      <AuthLayout>
        <Auth />
      </AuthLayout>
    ),
  },
  {
    path: URL.STYLE_GUIDE,
    element: <StyleGuide />,
  },
];
