import { FC, useEffect, useState } from "react";

import { Col, Form, Input, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Select from "../../components/Select";
import MaskInput from "../../components/MaskInput";

import { STATES_LIST } from "../../utils/enums";
import { getPlainPhoneNumber } from "../../utils/helpers";
import { PHONE_MASK, VALIDATE_MESSAGES } from "../../utils/constants";
import { IReferralCenterForm } from "../../interfaces/referralCenters";

import "./style.scss";

interface IProps {
  error?: string;
  success?: string;
  showModal: boolean;
  hideModal: () => void;
  isSubmitting: boolean;
  isLoadingForm?: boolean;
  referralCenterID?: string;
  errorHasExclamation?: boolean;
  initialFormData: IReferralCenterForm;
  handleSubmit: (formData: IReferralCenterForm) => void;
}

const EditReferralCenterFormModal: FC<IProps> = ({ errorHasExclamation = true, ...props }: IProps) => {
  const [form] = Form.useForm();

  const [formError, setFormError] = useState<string>("");
  const [disablePartnerCode, setDisablePartnerCode] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState("Add Referral Center");

  useEffect(() => {
    if (props.referralCenterID) {
      setModalTitle("Edit Referral Center");
      setDisablePartnerCode(true);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue(props.initialFormData);
  }, [props.initialFormData]);

  useEffect(() => {
    if (props.success && !props.initialFormData.name) {
      form.resetFields();
    }
  }, [props.success]);

  const handleClose = () => {
    props.hideModal();
    form.resetFields();
  };

  const handleSubmit = (): void => {
    setFormError("");
    const values: IReferralCenterForm = form.getFieldsValue();

    if (!values.pocMobilePhone && !values.pocOfficePhone) {
      setFormError("Please enter at least one phone number.");
      return;
    }

    values.pocMobilePhone = values.pocMobilePhone ? getPlainPhoneNumber(values.pocMobilePhone) : "";
    values.pocOfficePhone = values.pocOfficePhone ? getPlainPhoneNumber(values.pocOfficePhone) : "";

    props.handleSubmit(values);
  };

  return (
    <Modal
      title={modalTitle}
      error={formError || props.error}
      success={props.success}
      className="add-edit-rc-modal"
      loading={props.referralCenterID && props.isLoadingForm ? true : false}
      visible={props.showModal}
      onCancel={handleClose}
      errorHasExclamation={errorHasExclamation}
    >
      <Form
        form={form}
        className="rc-form"
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        validateMessages={VALIDATE_MESSAGES}
      >
        <div className="rc-form__inputs">
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="RC Name:" name="name" rules={[{ required: true, max: 50 }]}>
                <Input placeholder="RC Name" />
              </Form.Item>
              <Form.Item label="POC First Name:" name="pocFirstName" rules={[{ required: true, max: 25 }]}>
                <Input placeholder="POC First Name" />
              </Form.Item>
              <Form.Item label="POC Last Name:" name="pocLastName" rules={[{ required: true, max: 25 }]}>
                <Input placeholder="POC Last Name" />
              </Form.Item>
              <Form.Item label="POC Email:" name="pocEmail" rules={[{ required: true, type: "email" }, { max: 100 }]}>
                <Input placeholder="POC Email" />
              </Form.Item>
              <MaskInput
                label="POC Mobile Phone:"
                name="pocMobilePhone"
                placeholder="POC Mobile Phone"
                mask={PHONE_MASK}
              />
              <Row gutter={20}>
                <Col span={15}>
                  <MaskInput
                    label="POC Office Phone:"
                    name="pocOfficePhone"
                    placeholder="POC Office Phone"
                    mask={PHONE_MASK}
                  />
                </Col>
                <Col span={9}>
                  <Form.Item label="Ext:" name="pocOfficePhoneExt" rules={[{ max: 50 }]}>
                    <Input placeholder="Ext" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item label="POC Address 1:" name="pocAddress1" rules={[{ max: 100 }]}>
                <Input placeholder="POC Address 1" />
              </Form.Item>
              <Form.Item label="POC Address 2:" name="pocAddress2" rules={[{ max: 100 }]}>
                <Input placeholder="POC Address 2" />
              </Form.Item>
              <Select label="POC State:" name="pocState" placeholder="POC State" options={STATES_LIST} allowClear />
              <Form.Item label="POC City:" name="pocCity" rules={[{ max: 25 }]}>
                <Input placeholder="POC City" />
              </Form.Item>
              <Form.Item label="POC Zip:" name="pocZip" rules={[{ max: 10 }]}>
                <Input placeholder="POC Zip" />
              </Form.Item>
              <Form.Item
                className={disablePartnerCode ? "disabled" : ""}
                label="Partner Code:"
                name="partnerCode"
                rules={[{ required: !disablePartnerCode, max: 25 }]}
              >
                <Input placeholder="Partner Code" readOnly={disablePartnerCode} disabled={disablePartnerCode} />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className="rc-form__actions">
          <Button variant="link" htmlType="button" icon={<CloseOutlined />} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" htmlType="submit" loading={props.isSubmitting}>
            {props.isSubmitting ? "Saving..." : "Save"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditReferralCenterFormModal;
