import React, { FC } from "react";

import { Layout } from "antd";

import "./footer.scss";

const Footer: FC = () => {
  return (
    <Layout.Footer className="app-footer">
      <div className="app-footer__logo"></div>
      <div className="app-footer__copyright">Copyright © 2022 Meathead Movers</div>
    </Layout.Footer>
  );
};

export default Footer;
