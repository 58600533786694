export const CARD_DATA = [
  {
    followUpID: 1,
    dueDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString(),
    noteTitle: "Initial Meet",
    contactMethodName: 1,
    rcLastContactDaysAgo: 78,
    rcName: "Joe's Apartments",
    rcPocName: "John Doe",
  },
  {
    followUpID: 2,
    dueDate: new Date().toISOString(),
    noteTitle: "Meet Client for Coffee",
    contactMethodName: 1,
    rcLastContactDaysAgo: 20,
    rcName: "Joe's Apartments",
    rcPocName: "John Doe",
  },
  {
    followUpID: 3,
    dueDate: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).toISOString(),
    noteTitle: "Ping Customer About Partnership With Us",
    contactMethodName: 1,
    rcLastContactDaysAgo: 10,
    rcName: "Joe's Apartments",
    rcPocName: "John Doe",
  },
];

export const TABLE_DATA = [
  {
    id: "1",
    name: "XYZ Corp.",
    status: "pending",
    totalRevenue: 0.0,
    referrals: 0,
    closes: 0,
  },
  {
    id: "2",
    name: "Joe's Apartment",
    status: "active",
    totalRevenue: 3000.0,
    referrals: 2,
    closes: 2,
  },
  {
    id: "3",
    name: "Cal Poly",
    status: "stale",
    totalRevenue: 0.0,
    referrals: 10,
    closes: 0,
  },
  {
    id: "4",
    name: "Acme Inc.",
    status: "active",
    totalRevenue: 23000.5,
    referrals: 43,
    closes: 6,
  },
];
