import axios, { AxiosResponse } from "axios";
import { API_PREFIX } from "../utils/constants";
import { getAccessToken, getCurrentUser, setCurrentUser } from "./localStorage";
import useLogout from "../hooks/useLogout";

const API = axios.create({
  // .. where we make our configurations
  baseURL: API_PREFIX,
  timeout: 60000, // Now all requests using this instance will wait 30 seconds before timing out
});

API.defaults.headers.get["Accept"] = "application/json";

API.defaults.headers.post["Accept"] = "application/json";
API.defaults.headers.post["Content-Type"] = "application/json";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
API.interceptors.request.use((config: any) => {
  const token = getAccessToken();
  if (token && config) {
    config.headers.common.Authorization = `Bearer ${token}`;
  }
  return config;
});

API.interceptors.response.use(
  response => responseSuccessHandler(response),
  error => responseErrorHandler(error)
);

const responseSuccessHandler = async (response: AxiosResponse) => {
  if (response?.headers?.["must-refresh"]) {
    const res = await API.post("/users/jwt");
    if (res.status === 200 && res.data?.token) {
      const cUser = getCurrentUser();
      if (cUser) {
        cUser.token = res.data.token;
        setCurrentUser(cUser);
      }
    }
  }
  return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const responseErrorHandler = (error: any) => {
  // eslint-disable-next-line no-console
  console.log("<><>", error.response.status);
  if (error.response.status === 401) {
    return window.location.replace(useLogout());
  }

  return Promise.reject(error);
};

export default API;
