import { RouteObject } from "react-router-dom";

import { AuthGuard } from "./AuthGuard";
import { URL } from "../utils/constants";

import NoRole from "../pages/NoRole";
import Rbac from "../pages/Auth/Rbac";
import Dashboard from "../pages/Dashboard";
import FollowUps from "../pages/FollowUps";
import ReferralCenter from "../pages/ReferralCenter";

export const privateRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AuthGuard />,
    children: [
      {
        path: URL.DASHBOARD,
        element: (
          <Rbac
            elementForInvalidRoles={<NoRole />}
            elementForValidRoles={<Dashboard />}
            validRoles={["TSM", "Executive Manager"]}
          />
        ),
      },
      {
        path: URL.FOLLOW_UPS,
        element: <FollowUps />,
      },
      {
        path: URL.REFERRAL_CENTER + "/:id",
        element: <ReferralCenter />,
      },
    ],
  },
];
