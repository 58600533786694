import React, { FC, useState } from "react";

import cn from "classnames";
import { Card as AntdCard, Empty } from "antd";
import { SmileOutlined } from "@ant-design/icons";

import { IFollowUps } from "../../interfaces/followUps";
import { formatDateTime, isNewerThanToday, isToday } from "../../utils/helpers";

import "./style.scss";
import { CompleteFollowUp } from "../../pages/FollowUps/CompleteFollowUp";

interface ICardProps {
  data?: IFollowUps | undefined;
}

const Card: FC<ICardProps> = ({ data }: ICardProps) => {
  const [followUpId, setFollowUpId] = useState<number>(0);

  if (!data) {
    return (
      <AntdCard className="app-card app-card-no-data">
        <Empty image={<SmileOutlined />} description={<span>You&apos;re all caught up!</span>} />
      </AntdCard>
    );
  }

  let variant;
  if (isToday(data.dueDate)) {
    variant = "yellow";
  } else if (isNewerThanToday(data.dueDate)) {
    variant = "blue";
  } else {
    variant = "red";
  }
  const className = cn("app-card", { [`app-card__${variant}`]: variant });

  return (
    <>
      <AntdCard className={className} onClick={() => setFollowUpId(data.followUpID)}>
        <h1>{data.noteTitle}</h1>
        <p>
          <span>Last Contact: {data.rcLastContactDaysAgo} days</span>
          <span>Due: {formatDateTime(data.dueDate)}</span>
        </p>
        <h2>{data.rcPocName}</h2>
        <small>{data.rcName}</small>
      </AntdCard>
      <CompleteFollowUp followUpId={followUpId} closeModal={() => setFollowUpId(0)} />
    </>
  );
};

export default Card;
