import { FC } from "react";

import { Form } from "antd";
import InputMask from "react-input-mask";

import "./style.scss";

interface IMaskInputProps {
  label: string;
  name: string;
  placeholder: string;
  mask: string;
  isRequired?: boolean;
}

const MaskInput: FC<IMaskInputProps> = ({ label, name, placeholder, mask, isRequired = false }: IMaskInputProps) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: isRequired }]}>
      <InputMask className="ant-input ant-mask-input" mask={mask} placeholder={placeholder} />
    </Form.Item>
  );
};

export default MaskInput;
