import { IEnum } from "../interfaces/common";

export const RC_STATUS: IEnum[] = [
  {
    value: 1,
    label: "Pending",
  },
  {
    value: 2,
    label: "Active",
  },
  {
    value: 3,
    label: "Inactive", // renamed from Stale
  },
];

export const CONTACT_TYPE: IEnum[] = [
  {
    value: 1,
    label: "Phone",
  },
  {
    value: 2,
    label: "Email",
  },
  {
    value: 3,
    label: "In Person",
  },
  {
    value: 4,
    label: "Text",
  },
  {
    value: 5,
    label: "Video Call",
  },
  {
    value: 6,
    label: "Social Media",
  },
  {
    value: 7,
    label: "Other",
  },
];

export const FOLLOWUP_TYPE: IEnum[] = [
  {
    value: 1,
    label: "Custom",
  },
  {
    value: 2,
    label: "Concierge",
  },
  {
    value: 3,
    label: "Call Back",
  },
  {
    value: 4,
    label: "3 Day",
  },
  {
    value: 5,
    label: "10 Day",
  },
  {
    value: 6,
    label: "20 Day",
  },
  {
    value: 7,
    label: "40 Day",
  },
  {
    value: 8,
    label: "3 Day/QB",
  },
  {
    value: 9,
    label: "10 Day/QB",
  },
  {
    value: 10,
    label: "20 Day/QB",
  },
  {
    value: 11,
    label: "40 Day/QB",
  },
  {
    value: 12,
    label: "Invoice Review",
  },
  {
    value: 13,
    label: "4 Day",
  },
  {
    value: 14,
    label: "Create Excel",
  },
  {
    value: 15,
    label: "Sales IPQC",
  },
  {
    value: 16,
    label: "Ops IPQC",
  },
  {
    value: 17,
    label: "Create IP",
  },
  {
    value: 18,
    label: "Create Map",
  },
  {
    value: 19,
    label: "Need Unpacking",
  },
  {
    value: 20,
    label: "Web Request",
  },
  {
    value: 21,
    label: "Confirm Estimate",
  },
  {
    value: 22,
    label: "Concierge Call",
  },
  {
    value: 23,
    label: "Incoming Text",
  },
  {
    value: 24,
    label: "DocuSign",
  },
  {
    value: 25,
    label: "Estimate",
  },
  {
    value: 26,
    label: "2nd Chance",
  },
  {
    value: 27,
    label: "TOMs",
  },
  {
    value: 28,
    label: "Itinerary",
  },
  {
    value: 29,
    label: "Gift",
  },
  {
    value: 30,
    label: "Snail Mail",
  },
  {
    value: 31,
    label: "Quote",
  },
  {
    value: 32,
    label: "Quote Dollar",
  },
  {
    value: 33,
    label: "Lead Step 1",
  },
  {
    value: 34,
    label: "VSP",
  },
  {
    value: 35,
    label: "OM Reach Out",
  },
  {
    value: 36,
    label: "DocuSign Response",
  },
  {
    value: 37,
    label: "Incoming Email",
  },
  {
    value: 38,
    label: "Recording",
  },
  {
    value: 39,
    label: "Lead Step 2",
  },
  {
    value: 40,
    label: "Lead Step 3",
  },
  {
    value: 41,
    label: "Storage",
  },
  {
    value: 42,
    label: "Reschedule Move",
  },
  {
    value: 43,
    label: "Ad hoc",
  },
];

export const STATES_LIST = [
  {
    value: "AL",
    label: "Alabama (AL)",
  },
  {
    value: "AK",
    label: "Alaska (AK)",
  },
  {
    value: "AZ",
    label: "Arizona (AZ)",
  },
  {
    value: "AR",
    label: "Arkansas (AR)",
  },
  {
    value: "CA",
    label: "California (CA)",
  },
  {
    value: "CO",
    label: "Colorado (CO)",
  },
  {
    value: "CT",
    label: "Connecticut (CT)",
  },
  {
    value: "DE",
    label: "Delaware (DE)",
  },
  {
    value: "DC",
    label: "District of Columbia (DC)",
  },
  {
    value: "FL",
    label: "Florida (FL)",
  },
  {
    value: "GA",
    label: "Georgia (GA)",
  },
  {
    value: "HI",
    label: "Hawaii (HI)",
  },
  {
    value: "ID",
    label: "Idaho (ID)",
  },
  {
    value: "IL",
    label: "Illinois (IL)",
  },
  {
    value: "IN",
    label: "Indiana (IN)",
  },
  {
    value: "IA",
    label: "Iowa (IA)",
  },
  {
    value: "KS",
    label: "Kansas (KS)",
  },
  {
    value: "KY",
    label: "Kentucky (KY)",
  },
  {
    value: "LA",
    label: "Louisiana (LA)",
  },
  {
    value: "ME",
    label: "Maine (ME)",
  },
  {
    value: "MD",
    label: "Maryland (MD)",
  },
  {
    value: "MA",
    label: "Massachusetts (MA)",
  },
  {
    value: "MI",
    label: "Michigan (MI)",
  },
  {
    value: "MN",
    label: "Minnesota (MN)",
  },
  {
    value: "MS",
    label: "Mississippi (MS)",
  },
  {
    value: "MO",
    label: "Missouri (MO)",
  },
  {
    value: "MT",
    label: "Montana (MT)",
  },
  {
    value: "NE",
    label: "Nebraska (NE)",
  },
  {
    value: "NV",
    label: "Nevada (NV)",
  },
  {
    value: "NH",
    label: "New Hampshire (NH)",
  },
  {
    value: "NJ",
    label: "New Jersey (NJ)",
  },
  {
    value: "NM",
    label: "New Mexico (NM)",
  },
  {
    value: "NY",
    label: "New York (NY)",
  },
  {
    value: "NC",
    label: "North Carolina (NC)",
  },
  {
    value: "ND",
    label: "North Dakota (ND)",
  },
  {
    value: "OH",
    label: "Ohio (OH)",
  },
  {
    value: "OK",
    label: "Oklahoma (OK)",
  },
  {
    value: "OR",
    label: "Oregon (OR)",
  },
  {
    value: "PA",
    label: "Pennsylvania (PA)",
  },
  {
    value: "RI",
    label: "Rhode Island (RI)",
  },
  {
    value: "SC",
    label: "South Carolina (SC)",
  },
  {
    value: "SD",
    label: "South Dakota (SD)",
  },
  {
    value: "TN",
    label: "Tennessee (TN)",
  },
  {
    value: "TX",
    label: "Texas (TX)",
  },
  {
    value: "UT",
    label: "Utah (UT)",
  },
  {
    value: "VT",
    label: "Vermont (VT)",
  },
  {
    value: "VA",
    label: "Virginia (VA)",
  },
  {
    value: "WA",
    label: "Washington (WA)",
  },
  {
    value: "WV",
    label: "West Virginia (WV)",
  },
  {
    value: "WI",
    label: "Wisconsin (WI)",
  },
  {
    value: "WY",
    label: "Wyoming (WY)",
  },
];
