import { FC, useState } from "react";

import { Col, DatePicker, Divider, Form, Input, Radio, Row, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import Modal from "../../components/Modal";
import Table from "../../components/Table";
import Button from "../../components/Button";
import AppLayout from "../../components/Layout";
import Container from "../../components/Layout/Container";
import IconBlock from "../../components/IconBlock";
import FollowUpsCards from "../../components/FollowUpsCards";

import Logo from "../../assets/images/logo.png";
import { CARD_DATA, TABLE_DATA } from "./data";
import { formatCurrency } from "../../utils/helpers";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import PhoneIcon from "../../assets/images/phone-icon.svg";
import EmailIcon from "../../assets/images/envelope-icon.svg";
import InPersonIcon from "../../assets/images/in-person-icon.svg";
import TextIcon from "../../assets/images/text-icon.svg";
import VideoCallIcon from "../../assets/images/video-call-icon.svg";
import SocialMediaIcon from "../../assets/images/social-media-icon.svg";
import StartIcon from "../../assets/images/star-icon.svg";

import "./style.scss";

const StyleGuide: FC = () => {
  useDocumentTitle("Style Guide");

  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);

  const renderLogo = () => {
    return (
      <div className="app-flex app-flex__space-between app-flex__align-items-center">
        <img src={Logo} alt="logo" width={431} />
        <div className="app-style-guide__colors app-flex">
          <div className="app-style-guide__colors__item color-black"></div>
          <div className="app-style-guide__colors__item color-black-75"></div>
          <div className="app-style-guide__colors__item color-black-50"></div>
          <div className="app-style-guide__colors__item color-black-25"></div>
          <div className="app-style-guide__colors__item"></div>
        </div>
      </div>
    );
  };

  const renderFonts = () => {
    return (
      <>
        <div className="app-style-guide__fonts app-flex app-flex__center app-flex__align-items-center mt-10">
          <h1 className="app-style-guide__fonts__secondary">Alfa Slab One</h1>
        </div>
        <div className="app-style-guide__fonts app-flex app-flex__center app-flex__align-items-center mt-5">
          <h1>Open Sans</h1>
          <h1 className="app-style-guide_fonts__semi-bold">Open Sans Semi Bold</h1>
          <h1 className="app-style-guide__fonts__bold">Open Sans Bold</h1>
          <h1 className="app-style-guide__fonts__extra-bold">Open Sans Extra Bold</h1>
        </div>
      </>
    );
  };

  const renderCards = () => {
    return (
      <div className="app-style-guide__cards mt-10">
        <h1 className="title">Cards</h1>
        <FollowUpsCards data={CARD_DATA} />
      </div>
    );
  };

  const renderForms = () => {
    const plainOptions = ["Male", "Female", "Other"];

    return (
      <div className="app-style-guide__forms mt-10">
        <h1 className="title">Form Inputs</h1>

        <div className="ant-form-item app-flex app-flex__column">
          <label>Full Input</label>
          <Input placeholder="User Name" />
        </div>

        <Form form={form} layout="vertical">
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label="User Name:">
                <Input placeholder="User Name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="User Name: (Selected State)">
                <Input placeholder="User Name" value="John Doe" className="focus" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="User Name: (Error)" validateStatus="error">
                <Input placeholder="User Name" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Filter By:">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) =>
                    optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())
                  }
                >
                  <Select.Option value="1">Not Identified</Select.Option>
                  <Select.Option value="2">Closed</Select.Option>
                  <Select.Option value="3">Communicated</Select.Option>
                  <Select.Option value="4">Identified</Select.Option>
                  <Select.Option value="5">Resolved</Select.Option>
                  <Select.Option value="6">Cancelled</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Date:">
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Radio:">
                <Radio.Group options={plainOptions} value={plainOptions[0]} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="app-style-guide__buttons mt-10">
        <h1 className="title">Buttons</h1>

        <Row gutter={20}>
          <Col span={6}>
            <Button variant="primary" onClick={() => setShowModal(true)} block>
              Primary Button
            </Button>
          </Col>
          <Col span={6}>
            <Button variant="secondary" block>
              Add Button
            </Button>
          </Col>
          <Col span={6}>
            <Button block>Default Button</Button>
          </Col>
          <Col span={6}>
            <Button variant="link">Text Button</Button>
          </Col>
        </Row>
        <Row gutter={20} className="mt-5">
          <Col span={6}>
            <Button variant="primary" icon={<PlusOutlined />} block>
              Primary Button
            </Button>
          </Col>
          <Col span={6}>
            <Button variant="secondary" icon={<PlusOutlined />} block>
              Add Button
            </Button>
          </Col>
          <Col span={6}>
            <Button icon={<PlusOutlined />} block>
              Default Button
            </Button>
          </Col>
          <Col span={6}>
            <Button variant="link" icon={<PlusOutlined />}>
              Text Button
            </Button>
          </Col>
        </Row>
        <Modal
          title="Meet the client for coffee"
          subTitle="Complete Follow-up"
          visible={showModal}
          onCancel={() => setShowModal(false)}
        >
          <p>
            We last talked about sending leads for students in their apt block. Try to get him to bring paperwork this
            time.
          </p>
          <Divider />

          <p>
            We last talked about sending leads for students in their apt block. Try to get him to bring paperwork this
            time.
          </p>
        </Modal>
      </div>
    );
  };

  const renderIcons = () => {
    return (
      <div className="app-style-guide__icons_block mt-10">
        <h1 className="title mb-3">Icon Block</h1>

        <Row gutter={20}>
          <Col span={6}>
            <IconBlock icon={<img src={PhoneIcon} />} title="Phone" />
          </Col>
          <Col span={6}>
            <IconBlock icon={<img src={EmailIcon} />} title="Email" />
          </Col>
          <Col span={6}>
            <IconBlock icon={<img src={InPersonIcon} />} title="In Person" />
          </Col>
          <Col span={6}>
            <IconBlock icon={<img src={TextIcon} />} title="Text" />
          </Col>
        </Row>

        <Row gutter={20} className="mt-5">
          <Col span={6}>
            <IconBlock icon={<img src={VideoCallIcon} />} title="Video Call" />
          </Col>
          <Col span={6}>
            <IconBlock icon={<img src={SocialMediaIcon} />} title="Social Media" />
          </Col>
          <Col span={6}>
            <IconBlock icon={<img src={StartIcon} />} title="Other?" />
          </Col>
        </Row>
      </div>
    );
  };

  const renderTable = () => {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        className: "w-25",
        render: (status: string) => <span className="capitalize">{status}</span>,
      },
      {
        title: "Total Revenue",
        dataIndex: "totalRevenue",
        key: "totalRevenue",
        className: "w-35",
        render: (totalRevenue: number) => <span>{formatCurrency(totalRevenue)}</span>,
      },
      {
        title: "Referrals",
        dataIndex: "referrals",
        key: "referrals",
        className: "w-25",
      },
      {
        title: "Closes",
        dataIndex: "closes",
        key: "closes",
        className: "w-25",
      },
    ];

    return (
      <div className="app-style-guide__table mt-10">
        <h1 className="title">Tables</h1>

        <Table dataSource={TABLE_DATA} columns={columns} rowKey="id" />
      </div>
    );
  };

  return (
    <AppLayout className="app-style-guide">
      <Container>
        {renderLogo()}
        {renderFonts()}
        {renderCards()}
        {renderForms()}
        {renderButtons()}
        {renderIcons()}
        {renderTable()}
      </Container>
    </AppLayout>
  );
};

export default StyleGuide;
