import React from "react";

import { Dispatch, State } from "../reducers/appReducer";
import { useAppStore } from "../hooks/useAppStore";

export const AppContext = React.createContext<[State, Dispatch]>([{ isLoading: false }, () => null]);

export const AppContextProvider: React.FC = props => {
  const [state, dispatch] = useAppStore();

  return <AppContext.Provider value={[state, dispatch]}>{props.children}</AppContext.Provider>;
};
