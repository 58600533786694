import { URL } from "../utils/constants";
import AuthService from "../services/auth.service";

const useLogout = (isLogoutAction = false) => {
  let url = URL.LOGIN;
  if (!isLogoutAction && location.pathname !== "/") {
    url = `${url}?redirect=${location.pathname}`;
  }

  AuthService.logout();

  return url;
};

export default useLogout;
