import React, { FC } from "react";
import { Link } from "react-router-dom";

import { Result } from "antd";

import Container from "../../components/Layout/Container";

import "./style.scss";

const NotFound: FC = () => {
  return (
    <Container>
      <div className="not-found-page">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Link to="/" className="ant-btn ant-btn-primary">
              Back Home
            </Link>
          }
        />
      </div>
    </Container>
  );
};

export default NotFound;
