import React, { FC } from "react";

import cn from "classnames";
import { Layout } from "antd";

import Footer from "./Footer";
import Container from "./Container";

import "./style.scss";

interface IAuthLayoutProps {
  children: React.ReactNode;
  className?: string;
}

const AuthLayout: FC<IAuthLayoutProps> = (props: IAuthLayoutProps) => {
  return (
    <Layout className={cn("auth-layout", props.className)}>
      <Layout.Content>
        <Container>
          <div className="auth-page-wrapper">
            <div className="auth-box">{props.children}</div>
          </div>
        </Container>
      </Layout.Content>
      <Footer />
    </Layout>
  );
};

export default AuthLayout;
