import { FC, useContext } from "react";

import { Divider, Result } from "antd";

import { RcList } from "./RcList";
import { FollowUps } from "./FollowUps";
import Container from "../../components/Layout/Container";

import { AppContext } from "../../contexts/appContext";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { isCurrentUserExecutive } from "../../utils/helpers";

import "./style.scss";

const Dashboard: FC = () => {
  useDocumentTitle("Dashboard");
  const isExecutive = isCurrentUserExecutive();
  const [{ onBehalfOfUserId }, dispatch] = useContext(AppContext);

  return (
    <Container>
      {isExecutive && !onBehalfOfUserId ? (
        <div className="no-tsm-section">
          <Result title="Please select user to proceed." />
        </div>
      ) : (
        <div className="dashboard-wrapper">
          <FollowUps />
          <Divider />
          <RcList />
        </div>
      )}
    </Container>
  );
};

export default Dashboard;
