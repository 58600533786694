import { FC, useContext, useEffect, useState } from "react";

import moment from "moment";

import { Col, DatePicker, Form, Input, InputNumber, Radio, RadioChangeEvent, Row } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Select from "../../components/Select";

import { IEnum } from "../../interfaces/common";
import { ACTIONS } from "../../reducers/appReducer";
import { addDaysToDate } from "../../utils/helpers";
import { AppContext } from "../../contexts/appContext";
import { VALIDATE_MESSAGES } from "../../utils/constants";
import { IFollowUpsForm } from "../../interfaces/followUps";
import FollowUpsService from "../../services/followUps.service";
import { IReferralCenters } from "../../interfaces/referralCenters";
import ReferralCentersService from "../../services/referralCenters.service";

import "./add-manual-follow-up.scss";

interface IAddManualFollowUp {
  referralCenterId?: number;
}

export const AddManualFollowUp: FC<IAddManualFollowUp> = ({ referralCenterId }: IAddManualFollowUp) => {
  const [form] = Form.useForm();

  const [due, setDue] = useState("date");
  const [error, setError] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rcOptions, setRcOptions] = useState<IEnum[]>([]);
  const [{ onBehalfOfUserId }, dispatch] = useContext(AppContext);

  useEffect(() => {
    form.resetFields();
    setSuccess("");
    setError("");
    setDue("date");
    setDueDate("");

    if (showModal) {
      (async () => {
        await fetchReferralCenterOptions();
      })();
    }
  }, [showModal]);

  useEffect(() => {
    if (referralCenterId && rcOptions.length > 0) {
      form.setFieldsValue({
        referralCenterID: referralCenterId,
      });
    }
  }, [referralCenterId, rcOptions]);

  const fetchReferralCenterOptions = async () => {
    setIsLoading(true);
    const response = await ReferralCentersService.getOptions(onBehalfOfUserId);
    if (response && response.status === 200) {
      const thisRcOptions = response.data.map((rc: IReferralCenters) => {
        return {
          label: rc.name,
          value: rc.referralCenterID,
        };
      });
      setRcOptions(thisRcOptions);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (values: IFollowUpsForm) => {
    let isSuccess = false;
    setSuccess("");
    setError("");
    setIsSubmitting(true);
    if (due === "date") {
      values.dueDate = dueDate;
    } else {
      values.dueDate = addDaysToDate(Number(values.dueDays));
    }

    const response = await FollowUpsService.addManualFollowUp(values);
    delete values["dueDays"];

    if (response.status === 200) {
      setSuccess("Follow-up created.");
      form.resetFields();
      dispatch({
        type: ACTIONS.COMPLETE_FOLLOWUP,
        followUpID: response.data.followUpID,
      });
      isSuccess = true;
    } else {
      setError("There was an error while creating the follow-up.");
    }
    setIsSubmitting(false);

    if (isSuccess) {
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    }
  };

  return (
    <>
      <Button variant="secondary" icon={<PlusOutlined />} onClick={() => setShowModal(true)}>
        Add New Manual Follow-Up
      </Button>
      <Modal
        title="Add Manual Follow-up"
        error={error}
        success={success}
        loading={isLoading}
        visible={showModal}
        onCancel={() => setShowModal(false)}
      >
        <Form
          form={form}
          className="rc-form add-manual-follow-up-form"
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          validateMessages={VALIDATE_MESSAGES}
        >
          <div className="rc-form__inputs">
            <Row gutter={20}>
              <Col span={12}>
                <Select
                  label="RC:"
                  name="referralCenterID"
                  placeholder="Select"
                  options={rcOptions}
                  disabled={referralCenterId ? true : false}
                  isRequired
                />
                <Form.Item label="Follow-up date or days out:" className="add-manual-follow-up-form__due-radio">
                  <Radio.Group onChange={(e: RadioChangeEvent) => setDue(e.target.value)} value={due}>
                    <Radio value="date">
                      <Form.Item name="dueDate" rules={[{ required: due === "date" }]}>
                        <DatePicker
                          placeholder="yyyy-mm-dd"
                          disabled={due !== "date"}
                          disabledDate={(currentDate: moment.Moment) => {
                            const today = moment().format("YYYY-MM-DD");
                            const futureDate = moment(today).add(1, "year");
                            return (
                              currentDate &&
                              (currentDate < moment(today, "YYYY-MM-DD") ||
                                currentDate > moment(futureDate, "YYYY-MM-DD"))
                            );
                          }}
                          onChange={(_, dateString) => setDueDate(dateString)}
                        />
                      </Form.Item>
                    </Radio>
                    <Radio value="days">
                      <Form.Item name="dueDays" rules={[{ required: due === "days" }]}>
                        <InputNumber placeholder="Number of days out" min={1} disabled={due !== "days"} maxLength={3} />
                      </Form.Item>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Title:" name="title" rules={[{ required: true, max: 50 }]}>
                  <Input placeholder="Title" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="add-manual-follow-up-form__description-input"
                  label="Description:"
                  name="text"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea placeholder="Describe your client contact..." />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="rc-form__actions add-manual-follow-up-form__actions">
            <Button variant="link" htmlType="button" icon={<CloseOutlined />} onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" htmlType="submit" loading={isSubmitting}>
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
