import React, { FC } from "react";

import cn from "classnames";
import { Table as AntdTable } from "antd";

import ITableProps from "./interface";

import "./style.scss";

const Table: FC<ITableProps> = ({ dataSource, columns, className, isLoading = false, ...props }: ITableProps) => {
  const thisClassName = cn("app-table-wrapper", className);

  return (
    <AntdTable
      className={thisClassName}
      dataSource={dataSource}
      columns={columns}
      loading={isLoading}
      pagination={false}
      {...props}
    />
  );
};

export default Table;
