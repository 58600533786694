import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Form, Input } from "antd";

import Button from "../../components/Button";
import Logo from "../../assets/images/logo.png";
import { ILoginValues } from "../../interfaces/auth";
import { URL } from "../../utils/constants";
import useAuth from "../../hooks/useAuth";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useQueryString from "../../hooks/useQueryString";
import AuthService from "../../services/auth.service";

import "./style.scss";

const Auth = () => {
  useDocumentTitle("Login");

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const auth = useAuth();
  const queryString = useQueryString();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (auth) {
      navigate(URL.DASHBOARD);
    }
  }, []);

  const onFinish = (values: ILoginValues) => {
    setError("");
    setLoading(true);

    AuthService.login(values)
      .then(response => {
        if (response.status === 200) {
          const redirectUrl = queryString.get("redirect") || URL.DASHBOARD;
          navigate(redirectUrl);
        } else if (response.status === 400) {
          throw new Error(response.data);
        } else {
          throw new Error("There was an error logging in. Please try again.");
        }
      })
      .catch(err => {
        const resMessage = err.response?.data?.message || err.message || err.toString();

        setLoading(false);
        setError(resMessage);
      });
  };

  const renderLoginForm = () => {
    return (
      <Form form={form} name="login_form" layout="vertical" onFinish={onFinish} autoComplete="off">
        {error && <Alert message={error} type="error" className="mb-4" showIcon />}

        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
            {
              pattern: /^[a-zA-Z0-9@._-]+$/,
              message: "Invalid username",
            },
            {
              max: 50,
              message: "Username should not exceed 50 characters",
            },
          ]}
        >
          <Input disabled={loading} placeholder="Username" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            {
              max: 50,
              message: "Password should not exceed 50 characters",
            },
          ]}
        >
          <Input.Password disabled={loading} placeholder="Password" />
        </Form.Item>

        <Form.Item className="form-actions">
          <Button variant="primary" htmlType="submit" className="login-form-button" loading={loading} block>
            {loading ? "Please wait..." : "Login"}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className="login-form">
      <div className="login-form-header">
        <img src={Logo} alt="logo" />
        <h1>TSM Portal</h1>
      </div>

      {renderLoginForm()}
    </div>
  );
};

export default Auth;
