import { getCurrentUser } from "../services/localStorage";

export type LoggedInUser = {
  fullName: string;
  token: string;
  roles: string[];
  tsmId?: number;
};

const useAuth = (): LoggedInUser | null => {
  const user = getCurrentUser();
  if (user) {
    if (user.token) {
      return user;
    }
  }
  return null;
};

export default useAuth;
