import React from "react";
import { BrowserRouter } from "react-router-dom";

import AutoScrollToTop from "./components/Layout/AutoScrollToTop";
import { AppRoutes } from "./routes/index";

const App = () => {
  return (
    <BrowserRouter>
      <AutoScrollToTop>
        <AppRoutes />
      </AutoScrollToTop>
    </BrowserRouter>
  );
};

export default App;
