import { IFollowUps } from "../interfaces/followUps";
import { IReferralCenters } from "../interfaces/referralCenters";
import { History } from "../interfaces/contactHistory";

export enum ACTIONS {
  FETCH_ERROR = "FETCH_ERROR",
  SET_RC_CONTACT_HISTORY = "SET_RC_CONTACT_HISTORY",
  COMPLETE_FOLLOWUP = "COMPLETE_FOLLOWUP",
  SET_TSM_USER = "SET_TSM_USER",
}

export type State = {
  currentRC?: IReferralCenters;
  isLoading: boolean;
  error?: string;
  followUps?: IFollowUps[];
  contactHistory?: History[];
  lastCompletedFollowUpID?: number;
  onBehalfOfUserId?: number;
};

export type Action =
  | { type: ACTIONS.FETCH_ERROR; error: string }
  | { type: ACTIONS.SET_RC_CONTACT_HISTORY; contactHistory?: History[] }
  | { type: ACTIONS.COMPLETE_FOLLOWUP; followUpID: number }
  | { type: ACTIONS.SET_TSM_USER; onBehalfOfUserId: number };

type Reducer = React.Reducer<State, Action>;
export type Dispatch = React.Dispatch<Action>;

export const initialState: State = {
  isLoading: false,
};

export const appReducer: Reducer = (state, action) => {
  const setState = (isLoading: boolean, newState: Partial<State>) => ({ ...state, ...newState, isLoading });

  switch (action.type) {
    case ACTIONS.FETCH_ERROR:
      return setState(false, { error: action.error });
    case ACTIONS.SET_RC_CONTACT_HISTORY: {
      const { contactHistory } = action;
      return setState(false, {
        contactHistory,
      });
    }
    case ACTIONS.COMPLETE_FOLLOWUP: {
      return setState(false, {
        lastCompletedFollowUpID: action.followUpID,
      });
    }
    case ACTIONS.SET_TSM_USER: {
      return setState(false, { onBehalfOfUserId: action.onBehalfOfUserId });
    }
    default:
      return state;
  }
};
