import { DateTime, Interval } from "luxon";

import { getCurrentUser } from "../services/localStorage";
import { FOLLOWUP_TYPE, CONTACT_TYPE, RC_STATUS } from "./enums";

export const isCurrentUserExecutive = () => {
  const currentUser = getCurrentUser();
  return currentUser?.roles?.includes("Executive Manager") || false;
};

export const getInitials = (fullName: string | undefined) => {
  if (!fullName) {
    return "";
  }

  const allNames = fullName.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");

  return initials;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};

export const getPlainPhoneNumber = (phoneNumberString: string) => {
  return phoneNumberString.replace(/\D/g, "");
};

export const validatePhoneNumber = (phoneNumberString: string): boolean => {
  if (phoneNumberString) {
    const check = phoneNumberString.match(/\d/g);
    if (check && check.length === 10) {
      return true;
    }
  }

  return false;
};

export const formatCurrency = (number: number | string, currency = "USD") => {
  if (!number || isNaN(Number(number))) number = 0;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(Number(number));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createQueryString = (object: any) => {
  if (!object) return "";

  let query = "";
  Object.keys(object).forEach((key, i) => {
    if (i > 0 && object[key]) {
      query += "&";
    }
    if (object[key]) {
      query += `${key}=${object[key]}`;
    }
  });

  return query;
};

export const getRcStatus = (value: number | undefined, getNameOnly = false) => {
  if (!value) {
    return null;
  }
  const data = RC_STATUS.find(l => l.value === value);
  if (getNameOnly) {
    return data?.label || "(Invalid)";
  }

  return data;
};

export const getContactType = (value: number | undefined, getNameOnly = false) => {
  if (!value) {
    return null;
  }
  const data = CONTACT_TYPE.find(l => l.value === value);
  if (getNameOnly) {
    return data?.label || "Email";
  }

  return data;
};

export const getFollowUpType = (value: number | undefined, getNameOnly = false) => {
  if (!value) {
    return null;
  }
  const data = FOLLOWUP_TYPE.find(l => l.value === value);
  if (getNameOnly) {
    return data?.label || "Email";
  }

  return data;
};

export const isToday = (dateTime: string) => {
  const today = DateTime.now().toLocaleString();
  return DateTime.fromISO(dateTime).toLocaleString() === today;
};

export const isNewerThanToday = (dateTime: string) => {
  const now = DateTime.now();
  const later = DateTime.fromISO(dateTime);
  const interval = Interval.fromDateTimes(now, later);
  return interval.length() > 0;
};

export const formatDateTime = (dateTime: string, format = "M/d/yy") => {
  return DateTime.fromISO(dateTime).toFormat(format);
};

export const addDaysToDate = (days: number, dateTime = "") => {
  const thisDate = dateTime || DateTime.now().toISODate();
  return DateTime.fromISO(thisDate).plus({ days: days }).toISODate();
};

export const matchHeight = (parentId: string, childClass: string) => {
  const parentElem = document.getElementById(parentId);
  if (parentElem) {
    const parentHeight = parentElem?.clientHeight;
    const childElems = parentElem.querySelectorAll(childClass);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    childElems.forEach((elem: any) => (elem.style.height = `${parentHeight}px`));
  }
};

/**
 * Works only for fields with value type "number"
 *
 * @param fieldName object key
 * @param order ASC | DESC
 * @param dataToSort Array of objects to sort
 * @param preProcessor function that gets called for every value before calculating their sort position
 * @returns sortedData
 */
export const sortBy = (
  fieldName: string,
  order: "ASC" | "DESC",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataToSort: any[],
  preProcessor: (value: unknown) => string | number
) => {
  if (!dataToSort) return [];

  return dataToSort.sort((first, second) => {
    const firstValue = preProcessor(first[fieldName]);
    const secondValue = preProcessor(second[fieldName]);
    if (typeof firstValue !== typeof secondValue) return 1;
    if ([firstValue, secondValue].every(val => typeof val === "number")) {
      return order === "DESC"
        ? (secondValue as number) - (firstValue as number)
        : (firstValue as number) - (secondValue as number);
    } else {
      return 1;
    }
  });
};
