import { Collapse, Space } from "antd";

import IconBlock from "../IconBlock";

import PhoneIcon from "../../assets/images/phone-icon.svg";
import EmailIcon from "../../assets/images/envelope-icon.svg";
import InPersonIcon from "../../assets/images/in-person-icon.svg";
import TextIcon from "../../assets/images/text-icon.svg";
import VideoCallIcon from "../../assets/images/video-call-icon.svg";
import SocialMediaIcon from "../../assets/images/social-media-icon.svg";
import StartIcon from "../../assets/images/star-icon.svg";

import "./style.scss";

export type CollapsibleProps = {
  panels: (HeaderProps & {
    content: string;
    contactType: string;
    isActive?: boolean;
  })[];
};

export const Collapsible: React.FC<CollapsibleProps> = ({ panels }) => {
  return (
    <div className="collapsible-wrapper">
      <Collapse expandIconPosition="right">
        {panels.map(({ content, isActive, heading, ...headerProps }, i) => (
          <Collapse.Panel header={<Header {...headerProps} heading={heading} />} key={i}>
            {content}
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

type HeaderProps = {
  heading: string;
  subTitle: string;
  titleRightEnd?: string;
  contactType: any;
  subtitleRightEnd?: string;
};

const Header: React.FC<HeaderProps> = ({ heading, subTitle, titleRightEnd, contactType, subtitleRightEnd }) => {
  let IconImage;
  switch (contactType) {
    case "Phone":
      IconImage = PhoneIcon;
      break;
    case "Email":
      IconImage = EmailIcon;
      break;
    case "In Person":
      IconImage = InPersonIcon;
      break;
    case "Text":
      IconImage = TextIcon;
      break;
    case "Video Call":
      IconImage = VideoCallIcon;
      break;
    case "Social Media":
      IconImage = SocialMediaIcon;
      break;
    case "Other":
      IconImage = StartIcon;
      break;
    default:
      IconImage = StartIcon;
  }

  return (
    <div className="header-content no-select">
      <div className="header-content__icon">
        <IconBlock icon={<img src={IconImage} />} />
      </div>
      <div className="header-content__text">
        <div>
          <div className="header-content__text__heading">{heading}</div>
          <div className="header-content__text__sub-heading">{subTitle}</div>
        </div>
        <div className="header-content__text__date-time">
          <span>{titleRightEnd}</span>
          <span>{subtitleRightEnd}</span>
        </div>
      </div>
    </div>
  );
};
