import React, { FC, useState } from "react";

import "./container.scss";

interface IAppContainerProps {
  children: React.ReactNode;
}

const Container: FC<IAppContainerProps> = (props: IAppContainerProps) => {
  return <div className="app-container">{props.children}</div>;
};

export default Container;
