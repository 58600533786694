import API from "./axios";

class UserService {
  getTsms() {
    return API.get("/Users/tsms")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }
}

export default new UserService();
