import React, { FC } from "react";

import cn from "classnames";
import IIconBlockProps from "./interface";

import "./style.scss";

const IconBlock: FC<IIconBlockProps> = (props: IIconBlockProps) => {
  return (
    <div className={cn("app-icon-block", props.className)}>
      <div className="app-icon-block__icon">{props.icon}</div>
      {props.title && <div className={cn("app-icon-block__text", props.titleClassName)}>{props.title}</div>}
    </div>
  );
};

export default IconBlock;
