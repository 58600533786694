import { useRoutes } from "react-router-dom";

import { privateRoutes } from "./private";
import { publicRoutes } from "./public";
import NotFound from "../pages/NotFound";

export const AppRoutes = () => {
  const element = useRoutes([...publicRoutes, ...privateRoutes, { path: "*", element: <NotFound /> }]);

  return element;
};
