import React, { FC } from "react";

import cn from "classnames";
import { Card, Skeleton } from "antd";

import "./style.scss";

const CardSkeleton: FC = () => {
  return (
    <Card className="app-card">
      <Skeleton active paragraph={{ rows: 4 }} />
    </Card>
  );
};

export default CardSkeleton;
