import { FC } from "react";

import cn from "classnames";
import { Modal as AntdModal, Spin } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import IModalProps from "./interface";

import "./style.scss";

const Modal: FC<IModalProps> = ({
  onCancel,
  className,
  visible = false,
  loading = false,
  width = 895,
  title = "",
  subTitle = "",
  error = undefined,
  success = undefined,
  footer = false,
  errorHasExclamation = true,
  ...props
}: IModalProps) => {
  let extraClassName = "";
  if (success) {
    extraClassName = "app-modal-success";
  } else if (error) {
    extraClassName = "app-modal-error";
  }

  const thisClassName = cn("app-modal", extraClassName, className);

  return (
    <AntdModal
      maskClosable={false}
      className={thisClassName}
      visible={visible}
      onCancel={onCancel}
      title={false}
      footer={footer}
      width={width}
      centered
      {...props}
    >
      {error && (
        <div className="app-modal__message-wrapper">
          <div className="app-modal__message">
            {errorHasExclamation && <span>!!!</span>}
            <em>{error}</em>
            {errorHasExclamation && <span>!!!</span>}
          </div>
        </div>
      )}
      {success && (
        <div className="app-modal__message-wrapper">
          <div className="app-modal__message">
            <button type="button" aria-label="Close" className="ant-modal-close" onClick={onCancel}>
              <CloseOutlined />
            </button>
            <CheckOutlined /> <em>{success}</em>
          </div>
        </div>
      )}
      {subTitle && <h4 className="app-modal__sub-title">{subTitle}</h4>}
      {title && (
        <h1 className="app-modal__title">
          {props.headerIcon}
          {title}
        </h1>
      )}
      <div className="app-modal__content">
        {loading ? <Spin tip="Loading...">{props.children}</Spin> : props.children}
      </div>
    </AntdModal>
  );
};

export default Modal;
