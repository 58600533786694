import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { message } from "antd";

import { URL } from "../../utils/constants";
import Button from "../../components/Button";
import { sortBy } from "../../utils/helpers";
import { AddManualFollowUp } from "./AddManualFollowUp";
import { AppContext } from "../../contexts/appContext";
import { IFollowUps } from "../../interfaces/followUps";
import FollowUpsCards from "../../components/FollowUpsCards";
import FollowUpsService from "../../services/followUps.service";

import "./followUps.scss";

export const FollowUps: FC = () => {
  const [data, setData] = useState<IFollowUps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [{ lastCompletedFollowUpID, onBehalfOfUserId }] = useContext(AppContext);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [lastCompletedFollowUpID, onBehalfOfUserId]);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await FollowUpsService.getPriorities(onBehalfOfUserId);
    if (response && response.status === 200) {
      const followUpsSortedByOldest = sortBy("dueDate", "ASC", response.data.results, dueDate =>
        new Date(dueDate as string).getTime()
      );
      setData(followUpsSortedByOldest as unknown as IFollowUps[]);
    } else {
      message.error("Error in Fetching Uncompleted Follow-ups");
    }
    setIsLoading(false);
  };

  return (
    <div className="dashboard-wrapper__uncompleted-follow-ups">
      <h1 className="main-title">Uncompleted Follow-Ups</h1>
      <AddManualFollowUp />

      <div className="uncompleted-follow-ups-section__cards">
        <FollowUpsCards gutter={[20, 20]} data={showAll ? data : data.slice(0, 3)} isLoading={isLoading} />

        {!isLoading && (
          <div className="uncompleted-follow-ups-section__links">
            <Link className="app-link show-more-link" to={URL.FOLLOW_UPS}>
              View all Follow-Ups
            </Link>
            {data.length > 3 && (
              <Button variant="link" onClick={() => setShowAll(a => !a)}>
                {showAll ? "View Less" : `View all ${data.length} Priority Follow-ups`}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
