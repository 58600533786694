import API from "./axios";
import { createQueryString } from "../utils/helpers";
import { CompleteFollowUpPayload, FollowUpDetail, IFollowUpsForm } from "../interfaces/followUps";

declare const StatusTypes: ["true", "false"];
export interface IFollowUpGetParams {
  status?: typeof StatusTypes[number];
  userID?: string | number;
  TsmUserID?: number | undefined;
  referralCenterID?: string | number;
}

class FollowUpsService {
  get(params: IFollowUpGetParams | null = null) {
    let url = `/FollowUps/uncompleted`;
    const queryString = createQueryString(params);
    if (queryString) {
      url += `?${queryString}`;
    }
    return API.get(url)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  getPriorities(tsmUserID?: number) {
    return API.get(`/FollowUps/uncompleted?isPriority=true${tsmUserID ? "&TsmUserID=" + tsmUserID : ""}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  getUncompletedFollowUps(tsmUserID?: number) {
    return API.get(`/FollowUps/uncompleted${tsmUserID ? "?TsmUserID=" + tsmUserID : ""}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  addAdhocFollowUp(formData: IFollowUpsForm) {
    return API.post("/FollowUps/adhoc", {
      ...formData,
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  async getFollowUpDetail(followUpId: number, tsmUserID?: number) {
    return API.get(`/FollowUps/${followUpId}${tsmUserID ? "?tsmUserID=" + tsmUserID : ""}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  async completeFollowUp(followUp: CompleteFollowUpPayload, tsmUserID?: number): Promise<boolean | undefined> {
    const result = await API.put(
      `/FollowUps/${followUp.followUpID}/complete${tsmUserID ? "?tsmUserID=" + tsmUserID : ""}`,
      {
        ...followUp,
      }
    );

    return result.status === 200;
  }

  addManualFollowUp(formData: IFollowUpsForm) {
    return API.post("/FollowUps/manual", { ...formData })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }
}

export default new FollowUpsService();
