import { FC, ReactNode } from "react";

import PencilFillIcon from "remixicon-react/PencilFillIcon";
import DeleteBinFillIcon from "remixicon-react/DeleteBinFillIcon";

import "./style.scss";

interface ICardProps {
  title: string;
  children: ReactNode;
  actionEdit?: (() => void) | undefined;
  actionDelete?: (() => void) | undefined;
}

const SquareCard: FC<ICardProps> = ({ title, children, actionEdit, actionDelete }: ICardProps) => {
  return (
    <div className="square-card">
      <div className="square-card__header">
        <div className="square-card__header__title">{title}</div>
        <div className="square-card__header__actions">
          {actionEdit && (
            <div className="square-card__header__actions__item">
              <PencilFillIcon size={27} onClick={actionEdit} />
            </div>
          )}
          {actionDelete && (
            <div className="square-card__header__actions__item">
              <DeleteBinFillIcon size={27} onClick={actionDelete} />
            </div>
          )}
        </div>
      </div>
      <div className="square-card__body">{children}</div>
    </div>
  );
};

export default SquareCard;
