import React, { FC } from "react";

import cn from "classnames";
import { Button as AntdButton } from "antd";

import IButtonProps from "./interface";

import "./style.scss";

const Button: FC<IButtonProps> = ({ variant = "default", htmlType = "button", className, children, ...props }) => {
  const thisProps = { ...props };
  if (variant !== "secondary") {
    thisProps.type = variant;
  }

  const thisClassName = cn("app-button", { [`ant-btn-${variant}`]: variant === "secondary" }, className);

  return (
    <AntdButton className={thisClassName} htmlType={htmlType} {...thisProps}>
      {children}
    </AntdButton>
  );
};

export default Button;
