import React, { FC, useEffect } from "react";

import { Col, Row } from "antd";

import Card from "../Card";
import CardSkeleton from "../Card/skeleton";
import { IFollowUps } from "../../interfaces/followUps";
import { matchHeight } from "../../utils/helpers";

interface IFollowUpsCards {
  data: IFollowUps[];
  isLoading?: boolean;
  gutter?: number | [number, number];
}

const FollowUpsCards: FC<IFollowUpsCards> = ({ data, gutter = 20, isLoading = false }: IFollowUpsCards) => {
  useEffect(() => {
    matchHeight(data.length ? "followUpCardRow" : "empty", ".ant-col");
  }, []);

  return (
    <Row id="followUpCardRow" gutter={gutter}>
      {!isLoading ? (
        data.length ? (
          data.map((item: IFollowUps) => (
            <Col span={8} key={item.followUpID}>
              <Card data={item} />
            </Col>
          ))
        ) : (
          <Col span={24}>
            <Card />
          </Col>
        )
      ) : (
        <>
          <Col span={8}>
            <CardSkeleton />
          </Col>
          <Col span={8}>
            <CardSkeleton />
          </Col>
          <Col span={8}>
            <CardSkeleton />
          </Col>
        </>
      )}
    </Row>
  );
};

export default FollowUpsCards;
